import React, { useEffect, useState,useContext } from 'react';
import DashBoard from '../../components/DashBoard'
import ListTable from '../../components/table/Listtable';
import { Table } from '../../components/table/Table';
import { AppContext } from '../../context/appContext';
function LoanCloseList(props) {
    const column = ['AMOUNT','LOANID','PERIOD', 'DUE DATE','CLOSED DATE','CLOSED RATE', "GOLD RATE", 'SCHEME', 'INTEREST RATE']
    const key = ['amount', 'loanid','period', 'duedate', 'Closed_date', 'closed_rate', 'gold_rate', 'scheme', 'interest_rate']
    let [data,setData] = useState([])
    const{baseurl}=useContext(AppContext)

        useEffect(()=>{
        fetch(`${baseurl}goldloan/closed-loans/?date=2024-06-17`) // api for the get request
        .then(response => response.json())
        .then((data) => {
            setData(data.data)
            console.log(data.data)
        });
    },[])
    return (
        <div>
            <DashBoard />
            {/* <ListTable columns={column} datakey={key} data={data}/> */}
            <Table HEAD={column} datakey={key} TABLE_ROWS={data} />
        </div>
    );
}

export default LoanCloseList;