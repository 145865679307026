import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { AppContext } from "./appContext";

export const LoanContext = createContext();

function LoanProvider({ children }) {
  let { baseurl } = useContext(AppContext);
  // let [goldData, setGoldData] = useState({'application':''})
  let [scheme, setScheme] = useState();
  let [natureOfLoan, setNatureOfLoan] = useState();
  let [mode, setMode] = useState();
  let [items, setItems] = useState();
  let [goldrate, setGoldRate] = useState();

  useEffect(() => {
    axios
      .get(`${baseurl}loanschemes/scheme/`)
      // axios.get(`${baseurl}receiptpayment/scheme/`)
      .then(function (response) {
        // handle success
        console.log(response.data.data);
        setScheme(response.data.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    axios
      .get(`${baseurl}predbvalue/loan-nature/`)
      // axios.get(`${baseurl}receiptpayment/loan-nature/`)
      .then(function (response) {
        // handle success
        // console.log('op', response.data.data);
        setNatureOfLoan(response.data.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    axios
      .get(`${baseurl}predbvalue/transaction-type/`)
      .then(function (response) {
        // handle success
        // console.log('op', response.data.data);
        setMode(response.data.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    axios
      .get(`${baseurl}predbvalue/items/`)
      .then(function (response) {
        // handle success
        // console.log('op', response.data.data);
        setItems(response.data.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    //gold rate
    axios
      .get(`${baseurl}goldrate/`)
      .then(function (response) {
        // handle success
        // console.log('op', response.data.data.rate);
        setGoldRate(response.data.data.rate);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  const [formData, setFormData] = React.useState({
    goldloan: { cashbal: 50000, marigin: 0, adhaar: true, idcollected: ''},
    amount: [],
    details: [],
    totalWeight: 0.0,
    max_amount: 0,
  });

  let [customerDetail, setCustomerDetail] = useState({});
  const stepLoan = (formData, step) => {
    if (step === 1) {
      if (
        formData.goldloan.fk_customerid &&
        formData.goldloan.natureofloan &&
        customerDetail.full_name &&
        customerDetail.address1 &&
        customerDetail.address2 &&
        customerDetail.cusid &&
        customerDetail.city &&
        customerDetail.country &&
        customerDetail.state &&
        customerDetail.mob &&
        customerDetail.pin &&
        customerDetail.aadhaar
        // customerDetail.pan
   
      ) {
        if(!formData.goldloan.adhaar &&
          formData.goldloan.idcollected){
            return false
          }else if(formData.goldloan.adhaar){
            return false
          }
        
      }
    } else if (step === 2) {
      if (
        formData.goldloan.scheme &&
        formData.goldloan.period &&
        formData.goldloan.amount &&
        formData.goldloan.duedate &&
        formData.goldloan.gold_rate &&
        // formData.goldloan.interest_rate &&
        formData.amount.length > 0
      ) {
        if(parseFloat(formData.goldloan.amount) >= 50000){
          console.log("data amount,", formData.goldloan.pan)
          if(formData.goldloan.pan === ''){
            return false
          }
        }
        let temp = formData.amount.reduce((accumulator, currentValue) => {
          return accumulator + parseFloat(currentValue.amount);
        }, 0);
        if (temp === parseFloat(formData.goldloan.amount)) {
          return false;
        }
      }
    } else if (step === 3) {
      if (
        formData.details.length > 0 &&
        parseFloat(formData.goldloan.amount) <= parseFloat(formData.max_amount)
      ) {
        return false;
      }
    }
    return true;
  };
  return (
    <LoanContext.Provider
      value={{
        formData,
        setFormData,
        stepLoan,
        scheme,
        setScheme,
        natureOfLoan,
        mode,
        customerDetail,
        setCustomerDetail,
        items,
        setItems,
        goldrate,
      }}
    >
      {children}
    </LoanContext.Provider>
  );
}
export default LoanProvider;
