import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Title } from '../../titles/titles';
import Pagination from '../../components/Pagination';
import { AppContext } from '../../context/appContext';
import Table from './Table';
import DashBoard from '../../components/DashBoard';
import { formatToTudf } from '../utils/formatToTudf'; // Adjust path as needed
import './customer.css'

function CustomerList() {
  const today = new Date().toISOString().split('T')[0];
  const [customerName, setCustomerName] = useState('');
  const [aadhaarNumber, setAadhaarNumber] = useState('');

  const [customerId, setCustomerId] = useState('');
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFormChanged, setIsFormChanged] = useState(false); // Track form changes
  const itemsPerPage = 10;
  const { baseurl, comcode, brcode } = useContext(AppContext);
console.log('brocde', brcode)
  // Function to fetch and update customer data based on form inputs
  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseurl}customer/filter-comcode/${comcode}/${brcode}/`, {
        params: {
          aadhaar: aadhaarNumber,
          customer_id: customerId,
          customer_name: customerName,
          start_date: startDate,
          end_date: endDate,
          page: currentPage
        }
      });
      setCustomers(response.data.results.data); console.log('lol', response.data.results.data);
      setTotalItems(response.data.count);
      setError('');
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred');
      setCustomers([]);
    }
    setLoading(false);
  };

  // Function to fetch all customer data
  const fetchAllData = async () => {
    setLoading(true);
    try {
      let allCustomers = [];
      let page = 1;
      let totalRecords = 0;

      do {
        const response = await axios.get(`${baseurl}/customer/filter-comcode/${comcode}/${brcode}/`, {
          params: {
            aadhaar: aadhaarNumber,
            customer_id: customerId,
            customer_name: customerName,
            start_date: startDate,
            end_date: endDate,
            page: page
          }
        });

        allCustomers = allCustomers.concat(response.data.results.data);
        console.log('lol', response);

        totalRecords = response.data.count;
        page++;
      } while (allCustomers.length < totalRecords);

      return allCustomers; // Return all customers data
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred');
      return [];
    } finally {
      setLoading(false);
    }
  };
  // Handles form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    setCurrentPage(1); // Reset to the first page
    setIsFormChanged(true); // Indicate that the form has changed
  };

  const getCurrentDate = () => {
    const currentDate = new Date().toISOString().split('T')[0];
    return currentDate;
  };

  // Handles form reset
  const handleReset = () => {
    setStartDate('');
    setEndDate('');
    setCustomerId('')
    setAadhaarNumber('');
    setCustomerName('');
    setIsFormChanged(false); // Indicate that the form has not changed
    setCustomers([]); // Clear customer data
  };

  // Handles page change
  const handleSetActivePage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setIsFormChanged(true); // Indicate that the page has changed
  };

  // Fetch data when form is submitted, page is changed, or form inputs are changed
  useEffect(() => {
    if (isFormChanged) {
      handleSearch();
      setIsFormChanged(false); // Reset form changed state after data is fetched
    }
  }, [currentPage, isFormChanged]);

  // Fetch all data for export
  const handleExportToTudf = async () => {
    setLoading(true);
    try {
      const allCustomers = await fetchAllData(); // Ensure all data is fetched
      const tudfData = formatToTudf(allCustomers);
      const blob = new Blob([tudfData], { type: 'text/plain;charset=utf-8' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'customers.tudf'; // Name of the file to be downloaded
      document.body.appendChild(a); // Required for Firefox
      a.click();
      document.body.removeChild(a); // Clean up after download
      URL.revokeObjectURL(url); // Free up memory
    } catch (error) {
      console.error("Error exporting to TUDF:", error);
    }
    setLoading(false);
  };



  return (
    <div>
      <DashBoard />
      <h1 className='text-2xl text-center' style={{paddingTop:'60px'}}>Customer Details</h1>
      <form onSubmit={handleSubmit} className="flex w-11/12 ml-auto mr-auto mt-5 text-left flex-wrap gap-x-4">
        <div className="flex flex-wrap gap-x-4">
          <div className="flex flex-col w-1/4 sm:w-full lg:w-1/6">
            <label className="mb-1 text-sm">Customer Id</label>
            <input
              type="text"
              value={customerId}
              onChange={(e) => setCustomerId(e.target.value)}
              className="border rounded px-2 border-black w-full"
            />
          </div>

          <div className="flex flex-col w-1/4 sm:w-full lg:w-1/6">
            <label className="mb-1 text-sm">Name:</label>
            <input
              type="text"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              className="border rounded px-2 border-black w-full"
            />
          </div>

          <div className="flex flex-col w-1/4 sm:w-full lg:w-1/6">
            <label className="mb-1 text-sm">Aadhaar:</label>
            <input
              type="text"
              value={aadhaarNumber}
              onChange={(e) => setAadhaarNumber(e.target.value)}
              className="border rounded px-2 border-black w-full"
            />
          </div>

          <div className="flex flex-col w-1/4 sm:w-full lg:w-1/6">
            <label className="mb-1 text-sm">Start Date:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              max={getCurrentDate()}
              className="border rounded px-2 border-black w-full"
            />
          </div>

          <div className="flex flex-col w-1/4 sm:w-full lg:w-1/6">
            <label className="mb-1 text-sm">End Date:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              max={getCurrentDate()}
              min={startDate}
              className="border rounded px-2 border-black w-full"
            />
          </div>

          <div className="flex flex-wrap gap-2 w-full sm:mt-2">
            <button type="submit" className="bg-gray-500 text-white px-4 py-1 rounded-md hover:bg-gray-600 w-full sm:w-auto">
              Submit
            </button>
            <button type="button" onClick={handleReset} className="bg-gray-500 text-white px-4 py-1 rounded-md hover:bg-gray-600 w-full sm:w-auto">
              Reset
            </button>
            <button type="button" onClick={handleExportToTudf} className="bg-green-500 text-white px-4 py-1 rounded-md hover:bg-green-600 w-full sm:w-auto">
              Export to TUDF
            </button>
          </div>
        </div>
      </form>
      {loading && <div className="spinner"></div>} {/* loading spinner */}
      {error && <p>{error}</p>}
      {customers.length > 0 && ( // Only show the table if customers are available
        <>
          <Table
            TABLE_ROWS={customers}
            HEAD={['CUSTOMER ID', 'NAME', 'ADDRESS', 'ADDRESS2', 'EMAIL', 'COMPANY CODE', 'TRDATE', 'Branch Code', 'aadhaar']}
            datakey={['cusid', 'full_name', 'address1', 'address2', 'email', 'comcode', 'trdate', 'brcode', 'aadhaarverified']}
          />
          <Pagination
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            activePage={currentPage}
            setActivePage={handleSetActivePage}
          />
        </>
      )}
    </div>
  );
}

export default CustomerList;
