import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Title } from '../../titles/titles';
import DashBoard from '../../components/DashBoard';
import { AppContext } from '../../context/appContext';
import ApprovalModal from '../../components/ApprovalModal';
import UserTableComponent from '../../components/ApproveUserTable';
import '../customer/customer.css'

function UserTable() {
    const [users, setUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 7;
    const [loading, setLoading] = useState(false);

    let { baseurl, sidebarToggle} = useContext(AppContext) 

    useEffect(() => {
        fetchData();
    }, [currentPage]); // Fetch data whenever the current page changes

    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${baseurl}user/userlist/`, {
                params: { page: currentPage }
            });
            setUsers(response.data.data);
            console.log(response.data.data)
        } catch (error) {
            console.error('Error fetching users:', error);
        }
        setLoading(false)

    };

    const handleApprove = async (userId) => {
        setSelectedUserId(userId);
        setShowModal(true);
    };

    const confirmApprove = async () => {
        try {
            await axios.post(`${baseurl}user/approve-user/${selectedUserId}/`);
            // Update local state to reflect the approval
            setUsers(prevUsers =>
                prevUsers.map(user =>
                    user.id === selectedUserId ? { ...user, is_permission: true } : user
                )
            );
        } catch (error) {
            console.error('Error approving user:', error.response ? error.response.data : error.message);
        }
        setShowModal(false);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div>
            <DashBoard />
            <div className={`${!sidebarToggle?'ms-64 duration-500': 'ms-0 duration-500'}`}>
 



            <Title title="User Approve" />
            {loading && <div className="spinner"></div>} {/* loading spinner */}

            <UserTableComponent
                users={users}
                onApprove={handleApprove}
                TABLE_HEAD={["Username", "Email", "Phone", "Permission"]}
                datakey={["username", "email", "phone", "is_permission"]}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                onPageChange={handlePageChange}
            />
            <ApprovalModal
                isVisible={showModal}
                onClose={closeModal}
                onConfirm={confirmApprove}
            />
        </div>
        </div>
    );
}

export default UserTable;
