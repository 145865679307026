import React from 'react';
import DashBoard from '../../components/DashBoard';
import ItemForm from './itemform';

function Item(props) {
    return (
        <div>
            <DashBoard />
            <ItemForm/>
        </div>
    );
}
export default Item;