import React from 'react';
import DashBoard from '../../components/DashBoard';
import TypeOfTransation from './typeoftranform';

function TypeOfTransations(props) {
    return (
        <div>
            <DashBoard />
            <TypeOfTransation/>
        </div>
    );
}
export default TypeOfTransations;