import React, { useContext, useState } from 'react';
import { AppContext } from '../../context/appContext';
import { useForm } from "react-hook-form"
import Input from '../../components/Input';
import { Title } from '../../titles/titles';
import AlertMessage from '../../components/alert/Alert';

function TypeOfTransation() {
    const {
        register,
        handleSubmit,
        setError,
        reset,
        formState: { errors },
    } = useForm();
    const classes = 'form-control mt-4 block justify-between ps-2 w-full arrow_none';
    const { baseurl,sidebarToggle} = useContext(AppContext);
    const [open, setOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const url = `${baseurl}predbvalue/transaction-type/`;

    const onSubmit = async (data) => {
        try {
            const response = await fetch(url,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            const result = await response.json();
            const values = Object.values(result);
            if (response.status === 400 && result.errors) { // Check if result.error exists
                // Ensure result.error is an object before iterating over it
                if (typeof result.errors === 'object') {
                    for (const [key, value] of Object.entries(result.errors)) {
                        setError(key, {
                            type: 'server',
                            message: value

                        });

                    }
                }
            }
            else if (response.status === 201){
                reset()
                setSuccessMessage(result.message)
                setOpen(true)

            } 
        } catch (errors) {
            console.error("error:", errors);
        }
    };
   


    return (
        <div className={`${!sidebarToggle?'ms-64 duration-500': 'ms-0 duration-500'}`}>
        <div className='flex justify-center'>
            <div className="flex justify-center bg-white p-8 rounded-lg w-full ">
                <form onSubmit={handleSubmit(onSubmit)} className='md:border md:p-10 p-5 md:w-1/2 w-full'>
                    <Title title="Add Type of Transaction"/>
                    {successMessage && open && <AlertMessage open={open} setOpen={setOpen} message={successMessage}/>}
                    {errorMessage && <AlertMessage type="error">{errorMessage}</AlertMessage>}
                    <div className={classes}>
                        <Input
                            style={{ textAlign: 'left' }}
                            type="text"
                            name="payment_name"
                            label="Name"
                            errors={errors}
                            register={register}
                            validationSchema={{
                                required: "This field is required",
                                minLength: {
                                    value: 3,
                                    message: "Please enter a minimum of 3 characters"
                                }
                            }}
                            required
                        />
                    </div>
                    <div className='md:flex justify-end pr-10 sm: ml-10'>
                        <div className="flex justify-center mt-5">
                            <button
                                type="submit"
                                className="bg-gray-800 hover:bg-gray-950 text-white font-bold py-2 px-4 rounded"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            </div>
        </div>
    );
}

export default TypeOfTransation;
