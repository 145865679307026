import React from 'react';

function DaybookEditTable({ TABLE_ROWS, HEAD, datakey, onEdit }) {
  return (
    <table className="min-w-full bg-white border border-gray-300">
      <thead>
        <tr>
          {HEAD.map((head, index) => (
            <th key={index} className="py-2 px-4 border-b">{head}</th>
          ))}
          <th className="py-2 px-4 border-b">Actions</th>
        </tr>
      </thead>
      <tbody>
        {TABLE_ROWS.map((row, index) => (
          <tr key={index}>
            {datakey.map((key, idx) => (
              <td key={idx} className="py-2 px-4 border-b">{row[key]}</td>
            ))}
            <td className="py-2 px-4 border-b">
              <button onClick={() => onEdit(row)} className="text-blue-500 hover:underline">
                Edit
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default DaybookEditTable;
