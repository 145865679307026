import React, { createContext, useState } from 'react';
import {useForm } from "react-hook-form"

export const  LoanCloseContext = createContext()
function LoanCloseProvider({children}) {
    const [sajdata, setData] = useState(null);
    const [total, setTotal] = useState(0);
    const [total1, setTotal1] = useState(0);
    const [finaldc11, setFinaldc11] = useState(0);
    const [finalnotice11, setFinalnotice11] = useState(0);
    const [finalgst11, setFinalgst11] = useState(0);
    const [finalotc11, setFinalotc11] = useState(0);
    const [finalinterest11, setFinalinterest11] = useState(0);
    const [finalamt11, setFinalamt11] = useState(0);
    const [texname, setName] = useState(0);
    const [open1, setOpen1] = useState(false)  
    const [successMessage1, setSuccessMessage1] = useState()
    
    
    
    // console.log('oooooooooo',finaldc11);
    
    const {
        register,
        setValue,
       
        formState: { errors },
      } = useForm();
      const loan = {
          'amount': sajdata && sajdata.total_values.balamount,
          'gst': sajdata && sajdata.total_values.Servicecharge,
          'interest': sajdata && sajdata.total_values.intrestamt,
          'notice': sajdata && sajdata.total_values.notice,
          'otc': sajdata && sajdata.total_values.othercharge,
          'dc': sajdata && sajdata.total_values.documentation,         
          
        }
    
    // console.log('context', total1);
    
   
    return (
        <LoanCloseContext.Provider value={{
            loan,sajdata,setData,total,setTotal,total1, setTotal1,finaldc11, setFinaldc11,
            finalnotice11, setFinalnotice11,finalgst11, setFinalgst11,
            finalotc11, setFinalotc11,finalinterest11, setFinalinterest11,finalamt11, setFinalamt11,
            texname, setName, open1, setOpen1,successMessage1, setSuccessMessage1
    
        }}>
            {children}
        </LoanCloseContext.Provider>
    );
}
export default LoanCloseProvider;