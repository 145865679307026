import React, { useState, useEffect, useRef, useContext } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Button, Input } from "@material-tailwind/react";
import DashBoard from "../../components/DashBoard";
import imageSrc from "../../components/image/logo.png";
import PdfButton from "../../components/PdfButton";
import { useLocation } from "react-router";
import { AppContext } from "../../context/appContext";

function PanTicketKannada() {
  const {baseurl} = useContext(AppContext)
  const location = useLocation();
  const { state } = location;
  const initialLoanId = state?.loanid || "";

  const [loanId, setLoanId] = useState(initialLoanId); // Manage input value
  const [loanDetails, setLoanDetails] = useState(null);
  const [loanPerticular, setLoanPerticular] = useState(null);
  const [branchDetails, setBranchDetails] = useState(null);
  const [companyDetails, setCompanyDetails] = useState(null);

  // Fetch loan details when loanId changes
  useEffect(() => {
    const fetchLoanDetails = async () => {
      if (!loanId.trim()) return; // Skip fetch if loanId is empty or only whitespace

      try {
        const response = await fetch(
          `${baseurl}goldloan/fetch-loan/cfl/101/?loanid=${encodeURIComponent(
            loanId
          )}`,
          {
            method: "GET",
            mode: "cors",
          }
        );
        if (!response.ok) throw new Error("Network response was not ok");
        const data = await response.json();

        setLoanDetails(data.data);
        setBranchDetails(data.data[0].branch_details);
        setCompanyDetails(data.data[0].company_details);
      } catch (error) {
        console.error("Error fetching loan details:", error);
        setLoanDetails(null);
      }
    };

    fetchLoanDetails();
  }, [loanId]);

  // Fetch loan particulars when loanId changes
  useEffect(() => {
    const fetchLoanPerticulars = async () => {
      if (!loanId.trim()) return; // Skip fetch if loanId is empty or only whitespace

      try {
        const response = await fetch(
          `http://10.54.1.19:8000/goldloan/fetch-golddetails/cfl/101/?loanid=${encodeURIComponent(
            loanId
          )}`
        );
        if (!response.ok) throw new Error("Network response was not ok");
        const data = await response.json();
        setLoanPerticular(data.data);
      } catch (error) {
        console.error("Error fetching loan particulars:", error);
        setLoanPerticular(null);
      }
    };

    fetchLoanPerticulars();
  }, [loanId]);

  // Handle input change
  const handleInputChange = (event) => {
    setLoanId(event.target.value);
  };

  // Date formatting function
  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return date.toLocaleDateString();
  };

  // Total calculation function
  const calculateTotal = (propertyName) => {
    if (!Array.isArray(loanPerticular) || loanPerticular.length === 0) return 0;

    return loanPerticular
      .reduce((total, item) => {
        const value = parseFloat(item[propertyName]);
        return total + (isNaN(value) ? 0 : value);
      }, 0)
      .toFixed(2);
  };

  return (
    <>
      <DashBoard />
      <div
        className="w-11/12 mr-auto bg-white ml-auto font-medium"
        style={{ fontFamily: "sans-serif" }}
      >
        <div className="flex w-full shrink-0 gap-2 justify-around">
          <div className="w-full md:w-72 mt-20">
            <input
              className="static px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500 transition-all duration-300 w-full"
              placeholder="Search"
              value={loanId}
              onChange={handleInputChange} // Update state on change
            />
          </div>
        </div>

        {/* Loan Details Section */}
        {loanDetails && (
          <PdfButton filename="loan-application.pdf">
            <div className="page " id="page1">
              <div className="w-full">
                <div className="flex flex-row space-x-reverse">
                  <div>
                    <p className="text-lg">ಮೂಡಬೇಡಿಕೆ</p>
                    <p className="text-lg ml-10">
                      {loanDetails[0].full_name}
                      <br />
                      {loanDetails[0].address}
                      <br />
                      {loanDetails[0].address2}
                    </p>
                    <p className="text-lg">ಗಮ್ಯಸ್ಥಾನ</p>
                    <p className="text-lg ml-10">
                      ಆಡಳಿತಾಧಿಕಾರಿ
                      <br />
                      {branchDetails.brname}
                      <br />
                      ಫ್ಯಾಕ್ಸ್: 080-22210559 ಬೆಂಗಳೂರು
                    </p>
                    <p className="text-lg">ಶ್ರೀಮಾನ್,</p>
                    <p className="text-lg ml-10 text-lg font-mono">
                      ದಯವಿಟ್ಟು ನಾನು {loanDetails[0].amount} ರೂ. ಋಣವನ್ನು
                      ಬೆಲೆಬಾಳುವ {calculateTotal("ntweight")} ಗ್ರಾಂ ಚಿನ್ನದ ಆಭರಣಗಳ
                      ಭದ್ರತೆಯಲ್ಲಿ ನೀಡಲು ಅನುಮತಿಸು, ಈ ಮೂಲಕ ವಿವರಿಸಲಾಗಿದೆಯೇ ಎಂದು ಸಹ
                      ಒಪ್ಪಿಗೆಯೊಂದಿಗೆ ಬರುವುದಾಗಿದೆ.
                    </p>
                  </div>
                  <div className="flex flex-col items-end w-full">
                    <p className="justify-end ">
                      <span>ದಿನಾಂಕ</span>:
                      <span> {loanDetails[0].trdate}</span>
                    </p>
                    <p className="justify-end">
                      <span>{loanDetails[0].loanid}</span>
                    </p>
                    <img
                      src={loanDetails[0].image}
                      alt="ಗ್ರಾಹಕ"
                      className="w-36 h-36 rounded-md mt-10"
                    />
                  </div>
                </div>
                <h1 className="text-center font-bold text-lg mt-1 mb-3">
                  ಆಭರಣಗಳ ವಿವರಗಳು
                </h1>
                <div className="w-full">
                  <table className="text-sm w-full h-32 text-left text-lg">
                    <thead>
                      <tr className="bg-gray-100 h-5">
                        <th className="sm:px-4 py-2">ಅನುಕ್ರಮ ಸಂಖ್ಯೆ</th>
                        <th className="border px-2 sm:px-4 py-2">ಆಯ್ತುಗಳು</th>
                        <th className="border px-2 sm:px-4 py-2">ಸ್ಥಿತಿ</th>
                        <th className="border px-2 sm:px-4 py-2">ಗಣನೆ</th>
                        <th className="border px-2 sm:px-4 py-2">
                          ಗ್ರಾಸ್ ತೂಕ
                          <br />
                          ಗ್ರಾಂನಲ್ಲಿ
                        </th>
                        <th className="border px-2 sm:px-4 py-2">
                          ನೇರ ತೂಕ
                          <br />
                          ಗ್ರಾಂನಲ್ಲಿ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {loanPerticular &&
                        loanPerticular.map((item, index) => (
                          <tr key={index}>
                            <td className="text-xs border px-2 sm:px-4 py-2">
                              {item.sl_no}
                            </td>
                            <td className="text-sm border px-2 sm:px-4 py-2">
                              {item.item}
                            </td>
                            <td className="text-xs border px-2 sm:px-4 py-2">
                              {item.description}
                            </td>
                            <td className="text-xs border px-2 sm:px-4 py-2">
                              {item.count}
                            </td>
                            <td className="text-xs border px-2 sm:px-4 py-2">
                              {item.weight}
                            </td>
                            <td className="text-xs border px-2 sm:px-4 py-2">
                              {item.ntweight}
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <td className="text-xs border px-2 sm:px-4 py-2"></td>
                        <td className="text-xs border px-2 sm:px-4 py-2"></td>
                        <td className="text-sm border px-2 sm:px-4 py-2">
                          ಒಟ್ಟು
                        </td>
                        <td className="text-xs border px-2 sm:px-4 py-2"></td>
                        <td className="text-xs border px-2 sm:px-4 py-2">
                          {calculateTotal("weight")}
                        </td>
                        <td className="text-xs border px-2 sm:px-4 py-2">
                          {calculateTotal("ntweight")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="mt-5">ಸಹಿ</p>
                <span className="text-lg font-bold">
                  ಕೆವೈಸಿ ಒಪ್ಪುಗೊಳ್ಳಲಾಗಿದೆ
                </span>
                <span className="text-lg ml-20 font-bold">
                  ಆಧಾರ್ ಒಪ್ಪುಗೊಳ್ಳಲಾಗಿದೆ
                </span>
                <table className="border-2 border-gray-500 min-w-full mt-5 bg-white table-fixed">
                  <tbody>
                    <tr className="h-10">
                      <td className="text-sm border-2 border-gray-200 px-4 py-2 w-1/3">
                        ಆಭರಣಗಳ ಗ್ರಾಸ್, ನೇರ ತೂಕ, ಮೌಲ್ಯಮಾಪನದ ಮಟ್ಟವನ್ನು ನನಗೆ
                        ಪರಿಶೀಲಿಸಲಾಗಿದೆ ಮತ್ತು ಸರಿಯಾಗಿದೆ ಎಂದು ಪ್ರಮಾಣಿತವಾಗಿದೆ
                        <div className="text-sm text-center font-bold">
                          ಅಮೂಲಕ
                        </div>
                      </td>
                      <td className="text-sm border-2 border-gray-300 px-4 py-2 w-1/3">
                        ಗ್ರಾಸ್, ನೇರ ತೂಕ, ಮೌಲ್ಯಮಾಪನದ ಪ್ರಮಾಣವನ್ನು ಪರಿಶೀಲಿಸಲಾಗಿದೆ
                        ಮತ್ತು ಆಭರಣಗಳನ್ನು ಸಂಪಾದಿಸಲಾಗಿದೆ
                        <div className="text-sm text-center mt-3 font-bold">
                          ಜಂಟಿ ಜಮೀನಿದಾರ
                        </div>
                      </td>
                      <td className="text-sm border-2 border-gray-300 px-4 py-2 w-1/3">
                        {loanDetails[0].amount} ರೂ. ಋಣವನ್ನು ಮಂಜೂರಿಸಲಾಗಿದೆ
                        <div className="text-sm text-center mt-10 font-bold">
                          ಆಡಳಿತಾಧಿಕಾರಿ
                        </div>
                      </td>
                    </tr>
                    <tr className="w-full">
                      <td
                        className="text-sm text-center w-1/2 border-2 border-gray-300 px-4 py-2"
                        colSpan={2}
                      >
                        ಇಲ್ಲಿ ವಿವರಿಸಲಾದ ಆಭರಣಗಳನ್ನು ಪಾವನರ್‌ಗೆ ಹಿಂತಿರುಗಿಸಲಾಗಿದೆ
                        <div className="text-sm text-center mt-5 font-bold">
                          ಜಂಟಿ ಜಮೀನಿದಾರ
                        </div>
                      </td>
                      <td className="text-sm text-center w-1/2 border-2 border-gray-300 px-4 py-2">
                        ಕೊಟ್ಟಿರುವ ಆಭರಣಗಳನ್ನು ಸಂಪೂರ್ಣವಾಗಿ ಸ್ವೀಕರಿಸಲಾಗಿದೆ
                        <div className="text-sm text-center mt-5 font-bold mt-10">
                          ಪಾವನರ್/ಅನುಮತಿಸಿದ ಪ್ರತಿನಿಧಿಯ ಸಹಿ
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="text-sm h-20 text-center border-2 border-gray-300 px-4 py-2 w-full"
                        colSpan="2"
                      >
                        ನಿಲುವಿನಲ್ಲಿ ಹಾರ್ಜನ (ಇದಿದ್ದರೆ)
                      </td>
                      <td className="text-sm h-20 text-center border-2 border-gray-300 px-4 py-2 w-full">
                        {loanDetails[0].amount} ರೂ.
                        <div className="text-sm text-center mt-3 font-bold text-right mr-10">
                          ಆಧಿಕಾರಿ ಸಹಿ
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p className="mt-2 mb-5 text-sm">
                  ನಾನು ನೀಡಿರುವ ಒಪ್ಪಿಗೆ ವೆಚ್ಚದ ಐತಿಹಾಸಿಕ ಮೌಲ್ಯವು{" "}
                  {loanDetails[0].amount} ರೂ. (ರೂಪಾಯಿಯ________________________)
                  ಅಥವಾ ಯಾವುದೇ ಇಂಗಾಲ ಮೌಲ್ಯದ ಚಿನ್ನದ ಮೌಲ್ಯವನ್ನು ಹಿಂತಿರುಗಿಸುವಾಗ
                  ಕಡಿಮೆ ಎಂದು ಹೇಳುತ್ತೇನೆ.
                </p>
                <p className="mt-2 mb-5 text-lg text-right">ಅರ್ಜಿದಾರರ ಸಹಿ</p>
                <p className="font-bold text-center">
                  ಪಾವತಿ/ಅಗತ್ಯ ಅಧಿಕೃತ ಶ್ರೇಣಿಯು ಅಥವಾ ಇತರ ಶ್ರೇಣಿಯು ವಿವರಗಳು
                </p>
                <p>.</p>
                <hr />
              </div>
            </div>

            <div className="page" id="page2">
              <div className="w-full">
                <div className="flex justify-between">
                  <div>
                    <p className="font-bold text-sm">{loanDetails[0].loanid}</p>
                    <p className="font-bold text-sm">
                      ₹{loanDetails[0].amount}
                    </p>
                  </div>
                  <div>
                    <p className="text-right font-bold text-sm">
                      <span>ಸ್ಥಳ: </span>
                      <span> {loanDetails[0].city}</span>
                    </p>
                    <p className="text-right font-bold text-sm">
                      <span>ದಿನಾಂಕ: </span>
                      <span> {loanDetails[0].trdate}</span>
                    </p>
                  </div>
                </div>
                <p className="text-sm mt-10">
                  ದಾವಿ ಮಾಡಿದರೆ ನಾನು M/s. Chemm Finance Ltd ಅಥವಾ ಆಜ್ಞೆಯ ಪ್ರಕಾರ
                  ಬೆಂಗಳೂರಿನಲ್ಲಿ ₹{loanDetails[0].amount} ಮೊತ್ತವನ್ನು 22% ವಾರ್ಷಿಕ
                  ಬಡ್ಡಿಯೊಂದಿಗೆ (ಮ್ಯಾಕ್ಸ್) ಅಥವಾ ಕಂಪನಿಯು ಸಮಯಕ್ರಮದಲ್ಲಿ
                  ತೀರ್ಮಾನಿಸಬಹುದಾದ ಪರಿಷ್ಕೃತ ಬಡ್ಡಿದರಗಳಿಂದ ಪೂರ್ಣ ಹಣಪಾವತಿ ಮಾಡದ ತನಕ,
                  ಮೌಲ್ಯವನ್ನು ಪಡೆದೇನು ಎಂದು
                </p>
                <table className="text-sm min-w-full bg-white table-fixed mb-10 mt-10">
                  <tr className="h-20">
                    <td className="text-sm px-4 py-2 w-1/3">ಹೆಸರು</td>
                    <td className="text-sm px-4 py-2 w-1/3">
                      {loanDetails[0].full_name}
                      <br />
                      {loanDetails[0].address}
                      <br />
                      {loanDetails[0].address2}
                      <br />
                    </td>
                    <td className="text-center border-2 border-gray-800 px-4 py-2 w-1/3 h-40 text-xl font-bold">
                      ಮುದ್ರಣ
                    </td>
                  </tr>
                </table>
                <p className="text-sm">
                  <span>ಮೊಬೈಲ್: {loanDetails[0].mobile}</span>
                </p>
                <hr className="mt-40 border-3 border-gray-800 mb-10" />
                <h1 className="font-bold text-center">ಮೌಲ್ಯದ ರಸೀದಿ</h1>
                <p className="text-sm text-right font-bold mt-10">
                  <span>ಸ್ಥಳ: {loanDetails[0].city} </span>
                </p>
                <p className="text-sm text-right font-bold">
                  <span>ದಿನಾಂಕ: </span>
                  <span> {loanDetails[0].trdate}</span>
                </p>
                <p className="text-sm font-bold">{loanDetails[0].loanid}</p>
                <p className="text-sm font-bold">₹{loanDetails[0].amount}</p>
                <p className="text-sm">
                  ನಾನು/ನಾವು {loanDetails[0].full_name} ಈ ದಿನ CHEMM FINANCE LTD.
                  ಪರಿಕರದ ಹಕ್ಕುಗಳನ್ನು ಸ್ವೀಕರಿಸುತ್ತೇನೆ/ನಾವು ಸ್ವೀಕರಿಸುತ್ತೇವೆ.
                  ಫ್ಯಾಕ್ಸ್: 080-22210559
                </p>
                <div className="flex justify-between">
                  <p className="text-sm font-bold mt-20 mb-20">ಸಾಕ್ಷಿ</p>
                  <p className="text-sm font-bold mt-20 mb-20">ಹಸ್ತಾಕ್ಷರ</p>
                </div>
                <hr />
              </div>
            </div>

            <div className="page" id="page3">
              <div className="w-full">
                <div
                  style={{
                    padding: "20px",
                    backgroundColor: "white",
                    width: "100%",
                    fontFamily: "TimesRoman, Times New Roman, serif",
                  }}
                >
                  <div className="flex ">
                    <div>
                      <img
                        src={imageSrc}
                        alt="Chemmfinance"
                        className="w-36 h-36 rounded-md "
                      />
                    </div>
                    <div className="ml-20 text-center">
                      <p className="text-2xl">{companyDetails.comname}</p>
                      <p>{companyDetails.address}</p>
                      <p>ದೂರವಾಣಿ: {companyDetails.phone}, 22221115</p>
                      <p>ದಾಖಲಿತ: {branchDetails.address}</p>
                      <p>ಇಮೇಲ್: {branchDetails.email}</p>
                      <p>ಜಿಎಸ್ಟಿ: U85110KA1993PLC014599</p>
                    </div>
                  </div>
                  <p className="mt-10">ತಲುಪಿಸುತ್ತೇವೆ</p>
                  <h3 className="ml-5 font-bold text-lg">
                    {loanDetails[0].full_name},
                  </h3>
                  <p className="text-sm ml-10">
                    ₹{loanDetails[0].amount} ಮೊತ್ತದ ಸಾಲವು ನಿಮಗೆ ಚಿನ್ನದ ಆಭರಣಗಳ
                    ಒಪ್ಪಿಗೆಯ ವಿರುದ್ಧ ಅನುಮೋದಿಸಲಾಗಿದೆ.
                  </p>
                  <div className="flex justify-center">
                    <div className="flex justify-center w-10/12 mt-10">
                      <table className="text-sm bg-white w-full text-center">
                        <tbody>
                          <tr className="">
                            <td className="border px-4 py-2">
                              ಚಿನ್ನದ ಸಾಲ ಸಂಖ್ಯೆ
                            </td>
                            <td className="border px-4 py-2">
                              {loanDetails[0].loanid}
                            </td>
                            <td className="border px-4 py-2" rowSpan="3">
                              <div className="h-full flex flex-col justify-center">
                                <div>ಬಡ್ಡಿದರ</div>
                                <div>ತಿಂಗಳಿಗೆ ಸಂಪಾದಿತ</div>
                                <div>30 ದಿನಗಳಿಗೆ - 18%</div>
                                <div>31D to 60D - 20%</div>
                                <div>61D to 90D - 21%</div>
                                <div>91D to 365D - 22%</div>
                                <div>
                                  ಶಿಕ್ಷಾ ಶುಲ್ಕ: ಮ್ಯಾಕ್ಸ್ 3% (365 ದಿನಗಳಿಂದ ನಂತರ
                                  ಅನ್ವಯಿಸುತ್ತದೆ)
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr className="">
                            <td className="border px-4 py-2 font-medium">
                              ಒಪ್ಪಿಗೆಯ ದಿನಾಂಕ
                            </td>
                            <td className="border px-4 py-2">
                            {loanDetails[0].trdate}
                            </td>
                          </tr>
                          <tr className="">
                            <td className="border px-4 py-2 font-medium">
                              ಕಾಲಾವಧಿ
                            </td>
                            <td className="border px-4 py-2">
                              {loanDetails[0].fk_scheme.duration} ದಿನಗಳು
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <p className="text-center mt-5 mb-5">
                    ಪ್ರಾಸೆಸಿಂಗ್ ಶುಲ್ಕ: 0.10% ಪರಮಾವಧಿ ₹200
                  </p>
                  <h1 className="text-center font-bold text-lg mb-3">
                    ಆಭರಣಗಳ ವಿವರಗಳು
                  </h1>
                  <div className="w-full">
                    <table className="text-sm w-full min-w-max text-left text-lg">
                      <thead>
                        <tr className="bg-gray-100">
                          <th className="border px-2 sm:px-4 py-2">
                            ಅನುಕ್ರಮ ಸಂಖ್ಯೆ
                          </th>
                          <th className="border px-2 sm:px-4 py-2">ಆಯ್ತುಗಳು</th>
                          <th className="border px-2 sm:px-4 py-2">ಸ್ಥಿತಿ</th>
                          <th className="border px-2 sm:px-4 py-2">ಗಣನೆ</th>
                          <th className="border px-2 sm:px-4 py-2">
                            ಗ್ರಾಸ್ ತೂಕ (ಗ್ರಾಂನಲ್ಲಿ)
                          </th>
                          <th className="border px-2 sm:px-4 py-2">
                            ನೇರ ತೂಕ (ಗ್ರಾಂನಲ್ಲಿ)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {loanPerticular &&
                          loanPerticular.map((item, index) => (
                            <tr key={index}>
                              <td className="text-xs border px-2 sm:px-4 py-2">
                                {item.sl_no}
                              </td>
                              <td className="text-xs border px-2 sm:px-4 py-2">
                                {item.item}
                              </td>
                              <td className="text-xs border px-2 sm:px-4 py-2">
                                {item.description}
                              </td>
                              <td className="text-xs border px-2 sm:px-4 py-2">
                                {item.count}
                              </td>
                              <td className="text-xs border px-2 sm:px-4 py-2">
                                {item.weight}
                              </td>
                              <td className="text-xs border px-2 sm:px-4 py-2">
                                {item.ntweight}
                              </td>
                            </tr>
                          ))}
                        <tr>
                          <td className="text-xs border px-2 sm:px-4 py-2"></td>
                          <td className="text-xs border px-2 sm:px-4 py-2"></td>
                          <td className="text-xs border px-2 sm:px-4 py-2">
                            ಒಟ್ಟು
                          </td>
                          <td className="text-xs border px-2 sm:px-4 py-2">
                            {calculateTotal("count")}
                          </td>
                          <td className="text-xs border px-2 sm:px-4 py-2">
                            {calculateTotal("weight")}
                          </td>
                          <td className="text-xs border px-2 sm:px-4 py-2">
                            {calculateTotal("ntweight")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="flex justify-end pt-3">
                    ಸಮ್ಮತಿ ಹಕ್ಕುಪತ್ರದ ನಿಯಮಗಳನ್ನು ಒಪ್ಪಿಕೊಂಡಿದ್ದಾರೆ
                  </div>
                  <div
                    className="flex justify-between pt-5"
                    style={{ marginRight: "135px" }}
                  >
                    <div>
                      <p>ಮ್ಯಾನೇಜರ್</p>
                    </div>
                    <div>
                      <p>ಠಕ್ಕು</p>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>

            <div className="page" id="page4">
              <div className="w-full">
                <div className="p-2 bg-white">
                  <h1
                    className="text-2xl font-bold  text-center mb-3"
                    style={{ fontFamily: "Noto Sans Kannada Regular" }}
                  >
                    ನಿಯಮ ಮತ್ತು ಶರತ್ತುಗಳು
                  </h1>
                  <ol className="text-sm text-justify">
                    <li>
                      <div className="flex justify-between">
                        {" "}
                        {/* Wrapper for list item */}
                        <span className="font-bold">1. </span>
                        .ಒಂದು ನಿ ರ್ದಿ ಷ ್ಟ ಯೋ ಜನ ೆಯಡಿ ಯಲ್ಲಿ ನಿ ರ್ದಿ ಷ ್ಟಪಡಿ ಸದ ಹ
                        ೊ ರತು ಎಲ ್ಲಾ ಚಿ ನ್ನದ ಸ ಾಲಗಳನ್ನು ಗರಿ ಷ್ಠ 12 ತಿ ಂಗಳ ಅವಧಿ ಗ
                        ೆ ಮಂಜೂರು ಮ ಾಡಲ ಾಗುತ್ತದ
                      </div>
                    </li>
                    <li>
                      <div className="flex justify-between">
                        {" "}
                        {/* Wrapper for list item */}
                        <span className="font-bold">2. </span>
                        ಚಿ ನ್ನದ ಆಭರಣದ ಕ ಾನೂನುಬದ ್ಧ ಮ ಾಲೀ ಕರ ಾಗಿ ರುವ ವ್ಯ ಕ್ತಿ ಯು
                        RBI ಮ ಾರ್ಗ ಸೂಚಿ ಗಳ ಪ್ರಕ ಾರ KYC ನಿ ಯಮಗಳನ್ನು ಪೂ ರ ೈ ಸಿ ದ
                        ನಂತರ ಸ ಾಲವನ್ನು ಪಡ ೆಯಬಹುದು.{" "}
                      </div>
                    </li>
                    <li>
                      <div className="flex justify-between">
                        {" "}
                        {/* Wrapper for list item */}
                        <span className="font-bold">3. </span>
                        ಒತ ್ತೆ ಇಟ ್ಟ ಆಭರಣವು ಸ ಾಲಗ ಾರನ ಸಂಪೂ ರ್ಣ ಆಸ್ತಿ ಯ ಾಗಿ ದ ೆ,
                        ಬ ೇ ರ ೆ ಯ ಾವು ದ ೇ ವ್ಯ ಕ್ತಿ ಗ ೆ ಅದರ ಮೇ ಲ ೆ ಯ ಾವು ದ ೇ
                        ಹಕ್ಕು , ಆಸಕ್ತಿ ಅಥವ ಾ ಹಕ್ಕು ಇರುವು ದಿ ಲ್ಲ ಮತ್ತು ಸ ಾಲಗ ಾರನಿ
                        ಗ ೆ ಅದನ್ನು ಒತ ್ತೆ ಇಡುವ ಸಂಪೂ ರ್ಣ ಹಕ್ಕಿ ದ ೆ. ಆಭರಣದ ಶೀ ರ್ಷಿ
                        ಕ ೆಯಲ್ಲಿ ಯ ಾವು ದ ೇ ದ ೋ ಷದ ಸಂದರ್ಭ ದಲ್ಲಿ , ಸ ಾಲಗ ಾರನು
                        ಕಂಪನಿ ಗ ೆ ಎಲ ್ಲಾ ಹ ಾನಿ , ವ ೆಚ ್ಚಗಳು ಮತ್ತು ಪರಿ ಣ ಾಮಗಳ ಪರಿ
                        ಹ ಾರವನ್ನು ನೀ ಡುತ ್ತಾನ ೆ.{" "}
                      </div>
                    </li>
                    <li>
                      <div className="flex justify-between">
                        {" "}
                        {/* Wrapper for list item */}
                        <span className="font-bold">4. </span>
                        ಮಂಜೂರ ಾದ ಸ ಾಲವನ್ನು ಉತ ್ಪಾದನ ಾ ಉದ ್ದೇಶಗಳಿ ಗ ಾಗಿ ಮ ಾತ್ರ
                        ಬಳಸಬ ೇ ಕು ಮತ್ತು ಊಹ ಾತ್ಮ ಕ/ಅಕ್ರಮ/ಕ ಾನೂನುಬ ಾಹಿ ರ ಉದ
                        ್ದೇಶಗಳಿ ಗ ೆ ಬಳಸಬ ಾರದು.{" "}
                      </div>
                    </li>
                    <li>
                      <div className="flex justify-start">
                        {" "}
                        {/* Wrapper for list item */}
                        <span className="font-bold">5. </span>.ಹಣಕ ಾಸಿ ನ ಪ್ರ ಮ
                        ಾಣವನ್ನು 22-ಕ ್ಯಾ ರ ೆಟ್ ಚಿ ನ್ನ ಮತ್ತು ಆರ್ ಬಿ ಐ ಸೂಚನ ೆಗಳಿ
                        ಂದ ಒತ ್ತೆ ಇಟ್ಟಿ ರುವ ಆಭರಣಗಳ ನಿ ವ್ವಳ ತೂಕದಿ ಂದ ನಿ ರ್ಧ ರಿ ಸಲ
                        ಾಗುತ್ತದ ೆ.
                      </div>
                    </li>
                    <li>
                      <div className="flex justify-between">
                        {" "}
                        {/* Wrapper for list item */}
                        <span className="font-bold">6. </span>
                        ಎರವಲುಗ ಾರನು ಪ ್ಯಾ ನ್ ಟಿ ಕ ೆಟ್ , ಸ ್ಟ್ಯಾ ಂಪ್ ಡ್ಯೂ ಟಿ , ಜಿ
                        ಎಸ್ ಟಿ ಅಥವ ಾ ಯ ಾವು ದ ೇ ಇತರ ಶುಲ್ಕ ಗಳಲ್ಲಿ ಉಲ ್ಲೇಖಿ ಸಿ ದಂತ
                        ೆ ಪ್ರಕ್ರಿ ಯೆ ಗ ೆ ಸಂಬಂಧಿ ಸಿ ದ ಎಲ ್ಲಾ ಶುಲ್ಕ ಗಳನ್ನು ಭರಿ ಸಬ
                        ೇ ಕು ಮತ್ತು ಮರುಪ ಾವತಿ ಮ ಾಡಬ ೇ ಕು.{" "}
                      </div>
                    </li>
                    <li>
                      <div className="flex justify-between">
                        {" "}
                        {/* Wrapper for list item */}
                        <span className="font-bold">7. </span>. ಚಿ ನ್ನದ ಬ ೆಲ
                        ೆಗಳಲ್ಲಿ ನ ಏರಿ ಳಿ ತಗಳಿ ಂದ ಾಗಿ ಭದ್ರತ ೆಯ ಮ ೌಲ್ಯ ದಲ್ಲಿ ನ ಕ ೊ
                        ರತ ೆಯಿ ಂದ ಾಗಿ ಸಂಭವನೀ ಯ ನಷ ್ಟಕ ್ಕೆ ಕ ಾರಣವ ಾಗುವ ಸಂಭವನೀ ಯ
                        ಸಂದರ್ಭ ಗಳಲ್ಲಿ ಮೇ ಲ ೆ ನಿ ಗದಿ ಪಡಿ ಸಿ ದ ಮಂಜೂರ ಾದ ಅವಧಿ ಯ ಮುಕ
                        ್ತಾಯಕ ್ಕೆ ಕ ಾಯದ ೆ ಲ ೋ ನನ್ನು ಕರ ೆ ಮ ಾಡಲು ಮತ್ತು ಚಿ
                        ನ್ನವನ್ನು ಹರ ಾಜು ಮ ಾಡುವ ಹಕ್ಕ ನ್ನು ಕಂಪನಿ ಯು ಕ ಾಯ್ದಿ ರಿ ಸಿ
                        ಕ ೊ ಂಡಿ ದ ೆ. ಅಥವ ಾ ಯ ಾವು ದ ೇ ಇತರ ಕ ಾರಣಗಳಿ ಗ ಾಗಿ ಅಥವ ಾ ಯ
                        ಾವು ದ ೇ ಇತರ ಅನಿ ಶ ್ಚಯತ ೆಗಳ ಮೇ ಲ ೆ, ಸ ಾಲಗ ಾರನಿ ಗ ೆ ಕನಿ
                        ಷ್ಠ 14 ದಿ ನಗಳ ಸೂಚನ ೆಯನ್ನು ನೀ ಡಿ ದ ನಂತರ. (ವಿ ವರವ ಾದ ಹರ
                        ಾಜು ನೀ ತಿ ಯು ಕಂಪನಿ ಯ ವ ೆಬ್ ಸ ೈ ಟ್ ನಲ್ಲಿ ಲಭ್ಯ ವಿ ದ ೆ){" "}
                      </div>
                    </li>
                    <li>
                      <div className="flex justify-between">
                        {" "}
                        {/* Wrapper for list item */}
                        <span className="font-bold">8. </span>
                        .ಈ ಲ ೋ ನ್ ಸ್ಲಿ ಪ್ ಹ ೊ ಂದಿ ರುವವರು ಪ್ರತಿ ಜ್ಞೆ ಯನ್ನು ರಿ ಡೀ
                        ಮ್ ಮ ಾಡಲು ಅರ್ಹ ರ ಾಗಿ ರುವ ವ್ಯ ಕ್ತಿ ಎಂದು ಭ ಾವಿ ಸಲ ಾಗಿ ದ ೆ.
                        ವ ಾಗ ್ದಾನ ಮ ಾಡಿ ದ ಆಭರಣಗಳ ನಷ ್ಟದ ಸಂದರ್ಭ ದಲ್ಲಿ , ಕಂಪನಿ ಯ ಹ
                        ೊ ಣ ೆಗ ಾರಿ ಕ ೆಯು ಕಳ ೆದುಹ ೋ ದ ಆಭರಣವನ್ನು ಗಿ ರವಿ ಟಿ ಕ ೆಟ್
                        ನಲ್ಲಿ ಬಹಿ ರಂಗಪಡಿ ಸಿ ದ ಸಮ ಾನ ನಿ ವ್ವಳ ತೂಕದ ೊ ಂದಿ ಗ ೆ ಬದಲಿ
                        ಸಲು ಸೀ ಮಿ ತವ ಾಗಿ ರುತ್ತದ ೆ.{" "}
                      </div>
                    </li>
                    <li>
                      <div className="flex justify-between">
                        {" "}
                        {/* Wrapper for list item */}
                        <span className="font-bold">9. </span>
                        ಮುರಿ ದ/ಹ ಾನಿ ಗ ೊ ಳಗ ಾದ/ದ ೋ ಷಪೂ ರಿ ತ ಆಭರಣಗಳು /ಆಭರಣಗಳನ್ನು
                        ಸ ಾಲಗ ಾರನು ನಿ ರ್ದಿ ಷ ್ಟ ವಿ ನಂತಿ ಯನ್ನು ಮ ಾಡದ ಹ ೊ ರತು ಸ
                        ಾಲಕ ್ಕೆ ಭದ್ರತ ೆಯ ಾಗಿ ಸ್ವ ೀಕರಿ ಸುವು ದಿ ಲ್ಲ.{" "}
                      </div>
                    </li>
                    <li>
                      <div className="flex justify-between">
                        {" "}
                        {/* Wrapper for list item */}
                        <span className="font-bold">10. </span>ಶ ೇ ಖರಣ ೆ/ಆರ್ದ್ರ
                        ತ ೆ ಇತ ್ಯಾ ದಿ ಗಳಿ ಂದ ಕನಿ ಷ್ಠ ತೂಕ ನಷ ್ಟ (0 ರಿ ಂದ 500mgs)
                        ಯ ಾವ ಾಗಲೂ ಇರುತ್ತದ ೆ, ಇದನ್ನು ಎರವಲುಗ ಾರನು ವಿ ವ ಾದಿ ಸಬ ಾರದು{" "}
                      </div>
                    </li>
                    <li>
                      <div className="flex justify-between">
                        {" "}
                        {/* Wrapper for list item */}
                        <span className="font-bold">11. </span>
                        .ಬಡ್ಡಿ ಮತ್ತು ಇತರ ಶುಲ್ಕ ಗಳನ್ನು ಪ ್ಯಾ ನ್ ಟಿ ಕ ೆಟ್ /ಮಂಜೂರ
                        ಾತಿ ಪತ್ರದಲ್ಲಿ ನಮೂದಿ ಸಲ ಾಗಿ ದ ೆ. ಹ ೇ ಳಿ ದ ಅವಧಿ ಯೊ ಳಗ ೆ
                        ಮರುಪ ಾವತಿ ಮ ಾಡಿ ದರೂ ಸ ಾಲದ ಮೇ ಲ ೆ ಕನಿ ಷ್ಠ 15 ದಿ ನಗಳ ಬಡ್ಡಿ
                        ಯನ್ನು ವಿ ಧಿ ಸಲ ಾಗುತ್ತದ ೆ.
                      </div>
                    </li>
                    <li>
                      <div className="flex justify-between">
                        {" "}
                        {/* Wrapper for list item */}
                        <span className="font-bold">12. </span>. ಕಂಪನಿ ಯು ಸ ಾಲಗ
                        ಾರ ಅಥವ ಾ ಗ ್ಯಾ ರಂಟರ್ ಆಗಿ ಕಂಪನಿ ಗ ೆ ಕ ಾರಣವ ಾಗಿ ರುವ ಯ ಾವು
                        ದ ೇ ಇತರ ಹ ೊ ಣ ೆಗ ಾರಿ ಕ ೆಗಳ ವಿ ರುದ ್ಧ ಈ ಸ ಾಲಕ ್ಕೆ ನೀ ಡಲ
                        ಾದ ಭದ್ರತ ೆಯನ್ನು ಉಳಿ ಸಿ ಕ ೊ ಳ್ಳು ವ ಹಕ್ಕ ನ್ನು ಕಂಪನಿ ಯು ಹ ೊ
                        ಂದಿ ದ ೆ.{" "}
                      </div>
                    </li>
                    <li>
                      <div className="flex justify-between">
                        {" "}
                        {/* Wrapper for list item */}
                        <span className="font-bold">13. </span>
                        ಮಂಜೂರ ಾತಿ ನಿ ಯಮಗಳ ಪ್ರಕ ಾರ ಪೂ ರ್ಣ ಮರುಪ ಾವತಿ ಯನ್ನು ಮ ಾಡದಿ
                        ದ್ದರ ೆ, ಮಿ ತಿ ಮೀ ರಿ ದ ದಿ ನ ಾಂಕದಿ ಂದ ಕ ೆಳಗ ೆ ವಿ ವರಿ ಸಿ
                        ದಂತ ೆ ಗರಿ ಷ್ಠ 3% p.a ದಂಡದ ಶುಲ್ಕ ವನ್ನು ವಿ ಧಿ ಸಲ ಾಗುತ್ತದ ೆ
                        <br />
                        30 ದಿ ನಗಳ ಬ ಾಕಿ ದಿ ನ ಾಂಕ 2.00% ಅಕ್ರಮ
                        <br />
                        31 ದಿ ನಗಳಿ ಂದ 60 ದಿ ನಗಳವರ ೆಗ ೆ ಅಕ್ರಮಗಳ 2.25%
                        <br />
                        61 ದಿ ನಗಳಿ ಂದ 90 ದಿ ನಗಳವರ ೆಗ ೆ 2.50% ಅಕ್ರಮ
                        <br />
                        91 ದಿ ನಗಳಿ ಂದ 3.00% ಅಕ್ರಮವನ್ನು ಮುಚ್ಚುವವರ ೆಗ ೆ{" "}
                      </div>
                    </li>
                    <li>
                      <div className="flex justify-between">
                        {" "}
                        {/* Wrapper for list item */}
                        <span className="font-bold">14. </span>ವ ಾಗ ್ದಾನ
                        ಪತ್ರದಲ್ಲಿ ನಮೂದಿ ಸಲ ಾದ ಮಂಜೂರ ಾದ ಅವಧಿ ಯ ಪ್ರಕ ಾರ ನಿ ಗದಿ ತ
                        ದಿ ನ ಾಂಕದಂದು ಅಥವ ಾ ಮೊ ದಲು ಬ ಾಕಿ ಯನ್ನು ಪ ಾವತಿ ಸುವ ಮೂಲಕ
                        ಎರವಲುಗ ಾರನು ಆಭರಣಗಳನ್ನು ವಿ ಮೋ ಚನ ೆಗ ೊ ಳಿ ಸದಿ ದ್ದಲ್ಲಿ , ಬ
                        ಾಕಿ ಗಳ ಸ ಾಕ್ಷಾ ತ ್ಕಾ ರಕ ್ಕಾ ಗಿ ಆಭರಣಗಳನ್ನು ಹರ ಾಜು ಮ ಾಡಲ
                        ಾಗುವು ದು.{" "}
                      </div>
                    </li>
                    <li>
                      <div className="flex justify-between">
                        {" "}
                        {/* Wrapper for list item */}
                        <span className="font-bold">15. </span>
                        .ಇಲ್ಲಿ ಏನು ಹ ೇ ಳಿ ದ್ದರೂ ಸಹ, ಆಭರಣಗಳು /ಆಭರಣಗಳನ್ನು ಬಿ ಡುಗಡ
                        ೆ ಮ ಾಡುವ ಸಮಯದಲ್ಲಿ ಈ ಸ ಾಲವು ಏಕರೂಪವ ಾಗಿ ಸ್ಲಿ ಪ್ ಆಗುತ್ತದ ೆ.{" "}
                      </div>
                    </li>
                  </ol>
                  <div className="mt-4">
                    <p className="flex justify-end font-bold">
                      ನಾನು ಓದಿದ್ದೇನೆ, ಮೇಲಿನ ನಿಯಮಗಳು ಮತ್ತು ಷರತ್ತುಗಳನ್ನು
                      ಅರ್ಥಮಾಡಿಕೊಂಡಿದ್ದೇನೆ ಮತ್ತು ಒಪ್ಪಿದ್ದೇನೆ
                    </p>
                    <div
                      className="flex justify-end pt-5"
                      style={{ marginRight: "250px" }}
                    >
                      <p className="font-bold">ಸಾಲಗಾರ</p>
                    </div>
                    <p>ದಿನಾಂಕ: {loanDetails[0].trdate}</p>
                    <p>ಸ್ಥಳ: {branchDetails.address}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Additional loan details rendering */}
          </PdfButton>
        )}

        {/* Optional: Loading indicator */}
        {!loanDetails && (
          <div className="mt-5 p-4 border border-gray-300 rounded-lg">
            <p>Enter a valid Loan Id to get more Details...</p>
          </div>
        )}
      </div>
    </>
  );
}

export default PanTicketKannada;
