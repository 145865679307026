import React from 'react';
import LoginForm from './loginform';

function Login(props) {
    return (
        <div>
            
            <LoginForm />
            
        </div>
    );
}

export default Login;
