import React, { useRef } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const PdfButton = ({ children, filename }) => {
  const contentRef = useRef();

  const generatePDF = async () => {
    // const pdf = new jsPDF('p', 'mm', 'a4');
    const pdf = new jsPDF('p', 'mm', 'legal');
    const pages = document.querySelectorAll('.page');
    const margin = 10; // Adjust margin as needed

    try {
      for (let i = 0; i < pages.length; i++) {
        // Capture high-resolution image
        const canvas = await html2canvas(pages[i], {
          scale: 5, // Increase scale for higher resolution
          useCORS: true
        });
        const imgData = canvas.toDataURL('image/png', 1.0); // Ensure high quality
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = pdfWidth - (margin * 2);
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        if (i > 0) {
          pdf.addPage();
        }

        let positionX = margin;
        let positionY = margin;

        if (imgHeight <= pdfHeight - (margin * 2)) {
          pdf.addImage(imgData, 'PNG', positionX, positionY, imgWidth, imgHeight);
        } else {
          let heightLeft = imgHeight;
          while (heightLeft > 0) {
            if (heightLeft < imgHeight) {
              pdf.addPage();
            }
            const maxHeight = pdf.internal.pageSize.getHeight();
            const heightToPrint = Math.min(maxHeight, heightLeft);
            pdf.addImage(imgData, 'PNG', positionX, positionY, imgWidth, heightToPrint);
            heightLeft -= heightToPrint;
            positionY += heightToPrint;
          }
        }
      }

      return pdf.output('blob');
    } catch (error) {
      console.error('Error generating PDF:', error);
      throw new Error('Failed to generate PDF');
    }
  };

  const openPreviewWindow = async () => {
    try {
      const pdfBlob = await generatePDF();
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const newWindow = window.open(pdfUrl, '_blank');
      if (!newWindow) {
        throw new Error('Failed to open preview window. Please allow pop-ups for this site.');
      }
    } catch (error) {
      console.error('Error opening preview window:', error);
      // Handle error, e.g., display an alert to the user
    }
  };

  return (
    <div>
      <div className='flex justify-center mb-10'>
        <button 
          className='bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-600 focus:outline-none focus:bg-gray-600 transition-colors mt-5'
          onClick={openPreviewWindow}>
          Generate PDF
        </button>
      </div>
      <div ref={contentRef} style={{ padding: 20, backgroundColor: 'white', width: '210mm', margin: 'auto' }}>
        {children}
      </div>
    </div>
  );
};

export default PdfButton;
