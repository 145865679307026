import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../context/appContext';
import { useForm } from 'react-hook-form';
import Input from '../../components/Input';
import { Title } from '../../titles/titles';
import AlertMessage from '../../components/alert/Alert';

function PageForm() {
    const {
        register,
        handleSubmit,
        setError,
        reset,
        formState: { errors },
    } = useForm();
    const classes = 'form-control mt-4 block justify-between ps-2 w-full arrow_none';
    const { baseurl,sidebarToggle, comcode, brcode, gcode, ucode } = useContext(AppContext);
    const [open, setOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const url = `${baseurl}user/get-page/`;


    const onSubmit = async (data) => {
        try {
            console.log('Data sent to backend:', JSON.stringify(data)); // Log data being sent

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            const result = await response.json();
            console.log('Response from backend:', result); // Log response from backend

            if (response.ok) {
                reset();
            } if (response.status === 400 && result.error) {
                for (const [key, value] of Object.entries(result.error)) {
                    setError(key, {
                        type: 'server',
                        message: value,
                    });
                    setOpen(true)
                }
            } else if (response.status === 201) {
                setSuccessMessage(result.message);
                setOpen(true);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };


    return (
        <div className={`${!sidebarToggle?'ms-64 duration-500': 'ms-0 duration-500'}`}>
        <div className="flex justify-center">
            <div className="flex justify-center bg-white p-8 rounded-lg w-full ">
                <form onSubmit={handleSubmit(onSubmit)} className="md:border md:p-10 p-5 md:w-1/2 w-full">
                    <Title title="Add Page" />

                    {successMessage && open &&
                        <AlertMessage open={open}
                            setOpen={setOpen}
                            message={successMessage} />}
                    <div className="w-full px-2 mb-4">
                        <Input
                            style={{ textAlign: 'left' }}
                            type="text"
                            name="pagenumber"
                            label="Page Number"
                            errors={errors}
                            register={register}
                            validationSchema={{
                                required: 'This field is required',

                            }}
                            required
                        />
                    </div>
                    <div className="w-full px-2 mb-4">
                        <Input
                            style={{ textAlign: 'left' }}
                            type="text"
                            name="pagename"
                            label="Page Name"
                            errors={errors}
                            register={register}
                            validationSchema={{
                                required: 'This field is required',

                            }}
                            required
                        />
                    </div>
                    <div className="flex justify-center mt-5">
                        <button
                            type="submit"
                            className="bg-gray-800 hover:bg-gray-950 text-white font-bold py-2 px-4 rounded"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
            </div>
        </div>
    );
}

export default PageForm;
