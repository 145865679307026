import React from 'react';
import SimpleTable from '../../components/table/SimpleTable';

const LoanDetailModal = ({ isOpen, onClose, formData, customerDetail }) => {
    if (!isOpen) return null;

    const formatDate = (dateTimeString) => {
        const date = new Date(dateTimeString);
        return date.toLocaleDateString(); // This will format date in user's locale
    };
    let column = [
        "Name",
        "Description",
        "Weight",
        "Count",
        "Net Weight",
        "Purity",
      
      ];
      let datakey = [
        "item",
        "description",
        "weight",
        "count",
        "ntweight",
        "purity",
      ];
    

    return (
        <div className=" fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
            <div className="relative w-10/12 bg-white p-8 rounded-lg mt-20 mb-20">
                <button className="absolute top-5 right-5 text-right text-black-900" onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>

                </button>
                <h2 className="text-xl font-bold mb-4 text-center">Loan Details</h2>
        <div className='overflow-y-auto w-full flex justify-between'>
            <div className='w-3/4'>

            <table className="overflow-y-auto w-full divide-y divide-gray-200 border border-solid border-gray-300 rounded">
                <tbody className="bg-white divide-y divide-gray-200">
                    <tr className='h-10'> 
                        <td className="w-1/4 text-sm font-medium text-gray-700 ">
                            Customer ID
                        </td>
                        <td className="pl-2 text-sm text-gray-700">
                            {customerDetail.cusid}
                        </td>
                        <td className="w-1/4 text-sm font-medium text-gray-700 ">
                            Customer Name
                        </td>
                        <td className="pl-2 text-sm text-gray-700">
                            {customerDetail.full_name}
                        </td>
                    </tr>
                   
                    <tr className='h-10'>
                        <td className="w-1/4 text-sm font-medium text-gray-700 ">
                            Aadhaar No
                        </td>
                        <td className="pl-2 text-sm text-gray-700">
                            {customerDetail.aadhaar}
                        </td>
                      
                        <td className="w-1/4 text-sm font-medium text-gray-700 ">
                            Mobile No
                        </td>
                        <td className="pl-2 text-sm text-gray-700">
                            {customerDetail.mob}
                        </td>
                    </tr>
                    
                    <tr className='h-10'>
                        <td className="w-1/4 text-sm font-medium text-gray-700 ">
                            Address
                        </td>
                        <td className="pl-2 text-sm text-gray-700">
                            {customerDetail.address1},<br/> {customerDetail.address2}
                        </td>
                        
                        <td className="w-1/4 text-sm font-medium text-gray-700 ">
                            City
                        </td>
                        
                        <td className="pl-2 text-sm text-gray-700">
                          
                        </td>
                    </tr>
                    <tr className='h-10'>
                        <td className="w-1/4 text-sm font-medium text-gray-700 ">
                            State
                        </td>
                        <td className="pl-2 text-sm text-gray-700">
                          
                        </td>
                        <td className="w-1/4 text-sm font-medium text-gray-700 ">
                            Country
                        </td>
                        <td className="pl-2 text-sm text-gray-700">
                           
                        </td>
                    </tr>
                    <tr className='h-10'>
                        <td className="w-1/4 text-sm font-medium text-gray-700 ">
                            Pin Code
                        </td>
                        <td className="pl-2 text-sm text-gray-700">
                            {customerDetail.pin}
                        </td>
                       
                       
                       
                    </tr>
                    <tr className='h-10'>
                        <td className="w-1/4 text-sm font-medium text-gray-700 ">
                           Loan Amount
                        </td>
                        <td className="pl-2 text-sm text-gray-700">
                            {formData.goldloan.amount}
                        </td>
                        <td className="w-1/4 text-sm font-medium text-gray-700 ">
                          Due Date
                        </td>
                        <td className="pl-2 text-sm text-gray-700">
                            {formData.goldloan.duedate}
                        </td>
                       
                       
                    </tr>
                    <tr className='h-10'>
                        <td className="w-1/4 text-sm font-medium text-gray-700 ">
                           Gold Rate
                        </td>
                        <td className="pl-2 text-sm text-gray-700">
                            {formData.goldloan.gold_rate}
                        </td>
                        <td className="w-1/4 text-sm font-medium text-gray-700 ">
                          Period
                        </td>
                        <td className="pl-2 text-sm text-gray-700">
                            {formData.goldloan.period}
                        </td>
                        
                       
                    </tr>
                  
                    
                    
                </tbody>
            </table>
            
           
            </div>
            <div className='w-1/4 '>
                <img src={customerDetail.image}
                 alt=""
                 className='h-40 w-32 ml-20'
                 />
            </div>
        </div>
        <div className='border mt-1'>
        <SimpleTable
        columns={column}
        data={formData.details && formData.details}
        datakey={datakey}
        // deleteEle={deleteEle}
        // deleteElement={true}
        height="h-56"
      /></div>
        </div>
        </div>
    );
};

export default LoanDetailModal;
