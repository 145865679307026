import { useState } from 'react'
import Home from './home'
import DashBoard from '../../components/DashBoard'

function HomePage() {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle)
  }

  return (
    <div>
      <DashBoard/>
        <Home/>
    </div>
  )
}

export default HomePage
