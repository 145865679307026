import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import DashBoard from '../../components/DashBoard';
import { Table } from '../../components/table/Table';
import Pagination from '../../components/Pagination';
import { AppContext } from '../../context/appContext';

function GoldrateList() {
  const today = new Date().toISOString().split('T')[0];

  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);

  const [goldrate, setGoldrate] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  let { baseurl, comcode,sidebarToggle} = useContext(AppContext)
  const column = ['Rate','Date','description']
  const datakey = ['rate','date','description']

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseurl}goldrate/get-goldrate/${comcode}/`, {
        params: {
          start_date: startDate,
          end_date: endDate,
          page: currentPage
        }
      });
      setGoldrate(response.data.results.data);
      console.log(response);
      
      setTotalItems(response.data.count);
      setError('');
    } catch (error) {
      setError(error.response.data.error);
      setGoldrate([]);
    }
    setLoading(false);
  };
  const handleSetActivePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    handleSearch();
  }, [currentPage]);

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    setCurrentPage(1);
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);
    setCurrentPage(1);
  };
  return (
    <div>
      <DashBoard />
      <div className={`${!sidebarToggle?'ms-64 duration-500': 'ms-0 duration-500'}`}>

      <h1 className='pt-20 text-2xl text-center'>Gold Rate List</h1>
      <div className="flex w-11/12 ml-auto mr-auto text-left flex-wrap gap-x-4">
        <div className="flex items-center">
          <label className="mr-2">Start Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            className="border rounded px-2 py-1"
          />
        </div>
        <div className="flex items-center">
          <label className="mr-2">End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            className="border rounded px-2 py-1"
          />
        </div>
        <button
          onClick={handleSearch}
          className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
        >
          Search
        </button>
        
      </div>
      {loading && <div className="spinner"></div>} {/* loading spinner */}

      {error && <p>{error}</p>}
      <Table TABLE_ROWS={goldrate} datakey={datakey} HEAD={column} />      
      <Pagination
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        activePage={currentPage}
        setActivePage={handleSetActivePage}
      />
    </div>
    </div>
  );
}

export default GoldrateList;


