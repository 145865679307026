import React from 'react';
import DashBoard from '../../components/DashBoard';
import PageForm from './addpageform';

function AddPage(props) {
    return (
        <div>
            <DashBoard />
            <PageForm />       
        </div>
    );
}
export default AddPage;