import React from 'react';
import DashBoard from '../../components/DashBoard';
import RoleForm from './roleform';

function AddRole(props) {
    return (
        <div>
            <DashBoard />
            <RoleForm />       
        </div>
    );
}
export default AddRole;