import React from 'react';
import DashBoard from '../../components/DashBoard';
import RegisterForm from './registerform';



function RegisterUser(props) {
    return (
        <div>
            <DashBoard />
            <RegisterForm />
            
        </div>
    );
}

export default RegisterUser;