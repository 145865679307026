import { LoanContext } from "../../context/loanContext";
import LoanProvider from "../../context/loanContext";
import Fingerprint from "../../pages/goldloan/fingerprint";
import GoldDetails from "../../pages/goldloan/golddetails";
import GoldLoanForm from '../../pages/goldloan/goldloanform'
import GoldLoanAppForm from "../../pages/goldloanapplication/goldloanappform";
import React from 'react';

const StepDisplay = ({ step, currentStep, setCurrentStep, backGoldData, onSubmit }) => {
    
    switch (step) {
        case 1:
            return (
                
                    <GoldLoanAppForm
                    />
            );
        case 2:
            return (
                
                    <GoldLoanForm
                    />
                
            );
        case 3:
            return (
             
                    <GoldDetails
                    />
            );
        case 4:
            return (
                    <Fingerprint />
            );
        default:
            return null; // If step doesn't match any case, return null or handle default case accordingly
    }
};

export default StepDisplay;