import React from 'react';
import DashBoard from '../../components/DashBoard';
import SchemeForm from './schemeform';

function Scheme(props) {
    return (
        <div>
            <DashBoard />
            <SchemeForm/>
        </div>
    );
}
export default Scheme;