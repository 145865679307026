import React, {useState, useContext} from 'react';
import Input from '../../components/Input';
import { useForm } from "react-hook-form";
import AlertMessage from "../../components/alert/Alert";
import { AppContext } from '../../context/appContext';


function Test(props){

    const {
        register,
        handleSubmit,
        setError,
        reset,
        formState: { errors },
    } = useForm();
    const [open, setOpen] = useState(false)
    const [successMessage, setSuccessMessage] = useState()
    const { baseurl, brcode, comcode, gcode, ucode} = useContext(AppContext);
    const url = `${baseurl}loanschemes/scheme/`;



    const onSubmit = async (data) => {
        try {
            data.ucode = ucode;
            data.gcode = gcode;
            data.comcode = `${comcode}`;
            data.brcode =brcode

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            const result = await response.json(Object.values);
            //const values = Object.values(result);

            if (response.status === 400) {
                for (const [key, value] of Object.entries(result.errors)) {
                    console.log("error", result);
                    setError(key, {
                        type: 'server',
                        message: value
                    })
                }
            }
            else if (response.status === 201) {
                reset()
                setSuccessMessage(result.message)
                setOpen(true)
            }
        } catch (errors) {
            console.error("Error:", errors);
        }
        // reset()
    };

return(
    <div className="flex justify-center bg-white md:p-8 rounded-lg w-full">
        <form className="md:border md w-full py-10" onSubmit={handleSubmit(onSubmit)}>
        {successMessage && open && <AlertMessage open={open} setOpen={setOpen} message={successMessage} />}
        <div className="flex flex-wrap">
        {/* <div className="flex justify-between"> */}
            <div className='w-full md:w-1/3 px-2 mb-4'>
            <Input
            style = {{ textAlign: 'left' }}
            type = 'text'
            name = 'scheme_name'
            label="scheme name"
            errors={errors}
            register={register}
            validationSchema={{
                required: "This field is required",

            }}

            required
            />
            </div>
            <div className='w-full md:w-1/3 px-2 mb-4'>
            <Input
            style = {{ textAlign: 'left' }}
            type = 'number'
            name = 'minimum_period'
            label="period"
            errors={errors}
            register={register}
            validationSchema={{
                required: "This field is required",
                minLength: {
                    value: 3,
                    message: "Please enter a minimum of 3 characters"
                }

            }}

            required
            />
            </div>
            <div className='w-full md:w-1/3 px-2 mb-4'>
            <Input
            style = {{ textAlign: 'left' }}
            type = 'number'
            name = 'penal_intrest'
            label="Penal"
            errors={errors}
            register={register}
            validationSchema={{
                required: "This field is required",

            }}

            required
            />
            </div>
            <div className='w-full md:w-1/3 px-2 mb-4'>
            <Input
            style = {{ textAlign: 'left' }}
            type = 'text'
            name = 'penal_type'
            label="Penal Type"
            errors={errors}
            register={register}
            validationSchema={{
                required: "This field is required",

            }}

            required
            />
            </div>
            
            <div className='w-full md:flex justify-end pr-10 sm: ml-10'>

                        <div className="flex justify-center mt-5 md:pr-10">
                            <button
                                type="button"
                                // onClick={handleCancel}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Cancel
                            </button>
                        </div>
                        <div className="flex justify-center mt-5">
                            <button
                                type="submit"
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
        </div> </form>
    </div>
);
}
export default Test
