import React, { useState } from 'react';
import { Card, CardHeader, Typography, Button, CardBody, CardFooter, IconButton, Input } from "@material-tailwind/react";

function UserTableComponent({ TABLE_HEAD, datakey, users, onApprove, rowsPerPage }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedOption, setSelectedOption] = useState("all");

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;

    const filteredUsers = users.filter(user =>
        (datakey.some(key =>
            String(user[key]).toLowerCase().includes(searchTerm.toLowerCase())
        ) && (selectedOption === "all" ||
            (selectedOption === "approved" && user.is_permission) ||
            (selectedOption === "nonApproved" && !user.is_permission)))
    );


    const currentRows = filteredUsers.slice(indexOfFirstRow, indexOfLastRow);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <Card className="static h-full w-full">
            <CardHeader floated={false} shadow={false} className="static rounded-none">
                <div className="mb-4 flex flex-col justify-end gap-8 md:flex-row md:items-center">
                    <div>
                        <label>Filtered By: </label>
                        <select
                            className='border-2 bg-gray-200'
                            value={selectedOption}
                            onChange={(e) => {
                                setSelectedOption(e.target.value);
                                setCurrentPage(1);
                            }}
                        >
                            <option value="all">All Users</option>
                            <option value="approved">Approved Users</option>
                            <option value="nonApproved">Non-Approved Users</option>
                        </select>
                    </div>
                    <div className="flex w-full shrink-0 gap-2 md:w-max">
                        <div className="w-full md:w-72">
                            <input
                                style={{ textAlign: 'left' }}
                                label="Search"
                                className='form-control text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none mt-1'
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <Button className="flex items-center gap-3 bg-gray-700" size="sm" onClick={() => setCurrentPage(1)}>
                            Search
                        </Button>
                    </div>
                </div>
            </CardHeader>
            <CardBody className="overflow-scroll px-0">
                <table className="w-full min-w-max table-auto text-left">
                    <thead>
                        <tr>
                            {TABLE_HEAD.map((head, index) => (
                                <th
                                    key={index}
                                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 px-4 py-1"
                                >
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal leading-none opacity-70"
                                    >
                                        {head}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {currentRows.map((user, rowIndex) => (
                            <tr key={user.id}>
                                {datakey.map((key, colIndex) => (
                                    <td key={colIndex} className="px-4 whitespace-nowrap">
                                        {key === 'is_permission' ? (user.is_permission ? 'Yes' : 'No') : user[key]}
                                    </td>
                                ))}
                                
                            </tr>
                        ))}
                    </tbody>
                </table>
            </CardBody>
            <CardFooter className="flex items-center justify-center border-t border-blue-gray-50 p-4 gap-10">
                <Button
                    variant="outlined"
                    size="sm"
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Previous
                </Button>
                <div className="flex items-center gap-2">
                    {Array.from({ length: Math.ceil(filteredUsers.length / rowsPerPage) }, (_, index) => (
                        <IconButton
                            key={index}
                            variant={currentPage === index + 1 ? "filled" : "text"}
                            size="sm"
                            onClick={() => paginate(index + 1)}
                        >
                            {index + 1}
                        </IconButton>
                    ))}
                </div>
                <Button
                    variant="outlined"
                    size="sm"
                    onClick={() => paginate(currentPage + 1)}
                    disabled={indexOfLastRow >= filteredUsers.length}
                >
                    Next
                </Button>
            </CardFooter>
        </Card>
    );
}

export default UserTableComponent;
