import React, { useContext, useState } from 'react';
import { AppContext } from '../../context/appContext';
import { useForm } from "react-hook-form"
import Input from '../../components/Input';
import { Title } from '../../titles/titles';
import AlertMessage from '../../components/alert/Alert';

function GroupCodeForm() {
    const {
        register,
        handleSubmit,
        setError,
        reset,
        formState: { errors },
    } = useForm();
    const classes = 'form-control mt-4 block justify-between ps-2 w-full arrow_none';
    const { baseurl, sidebarToggle, comcode, brcode, ucode, gcode} = useContext(AppContext);
    const [open, setOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage] = useState('');

    const url = `${baseurl}accounting/add-grouphead/`;
    const handleCancel = () => {
        reset();
    };

    const onSubmit = async (data) => {
        try {
            data.comcode = `${comcode}`;
            data.brcode = `${brcode}`;
            data.ucode = ucode;
            data.gcode = gcode;
            const response = await fetch(url,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const result = await response.json();
            if (response.status === 400 && result.error) {
                if (typeof result.error === 'object') {
                    for (const [key, value] of Object.entries(result.error)) {
                        setError(key, {
                            type: 'server',
                            message: value

                        });
                    }
                }
            }
            else if (response.status === 201){
                handleCancel()
                setSuccessMessage(result.message)
                setOpen(true)

            } 
        } catch (error) {
            console.error("error:", error);
        }
    };
    return (
        <>
        <div className={`${!sidebarToggle?'ms-64 duration-500': 'ms-0 duration-500'}`}>
        <div className='flex justify-center py-20'>
            <div className="flex justify-center bg-white p-8 rounded-lg w-full ">
                <form onSubmit={handleSubmit(onSubmit)} className='md:border md:p-10 p-5 md:w-1/2 w-full'>
                    <Title title="Add Group Head"/>
                    {successMessage && open && <AlertMessage open={open} setOpen={setOpen} message={successMessage}/>}
                    {errorMessage && <AlertMessage type="error">{errorMessage}</AlertMessage>}
                    <div className={classes}>
                        <Input
                            style={{ textAlign: 'left' }}
                            type="text"
                            name="group_hcode"
                            label="Group Head Code"
                            errors={errors}
                            register={register}
                            validationSchema={{
                                required: "This field is required",
                                minLength: {
                                    value: 3,
                                    message: "Please enter a minimum of 3 characters"
                                }
                            }}
                            required
                        />
                    </div>
                    <div className={classes}>
                        <Input
                            style={{ textAlign: 'left' }}
                            type="text"
                            name="name"
                            label="Name"
                            errors={errors}
                            register={register}
                            validationSchema={{
                                required: "This field is required",
                                minLength: {
                                    value: 3,
                                    message: "Please enter a minimum of 3 characters"
                                }
                            }}
                            required
                        />
                    </div>
                    <div className='md:flex justify-around sm: ml-10'>
                        <div className="flex justify-center mt-5">
                            <button
                                type="submit"
                                className="w-20 bg-gray-800 hover:bg-gray-950 text-white font-bold py-2 px-4 rounded"
                            >
                                Submit
                            </button>
                        </div>
                        <div className="flex justify-center mt-5">
                            <button
                                type="button"
                                onClick={handleCancel}
                                className="w-20 bg-gray-800 hover:bg-gray-950 text-white font-bold py-2 px-4 rounded md:mr-10 lg:mr-10"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </div>
        </>
    );
}

export default GroupCodeForm;
