import React, { createContext, useState } from "react";

export const AadhaarContext = createContext();

function AadhaarProvider({ children }) {
  const [aadharDetails, setAadharDetails] = useState();
  // const [verified, setVerified] = useState(false);
  //let aadhaarurl = 'http://127.0.0.1:8000'
  let aadhaarurl = 'https://saj.chemmanurfinance.com'

  return (
    <AadhaarContext.Provider
      value={{
        aadharDetails,
        setAadharDetails,
        aadhaarurl,
        
      }}
    >
      {children}
    </AadhaarContext.Provider>
  );
}
export default AadhaarProvider;
