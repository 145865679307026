export const formatToTudf = (customers) => {
  // Define TUDF Header as required
  const tudfHeader = "Your TUDF Header";
  const genderMap = {
    female: '1', // Female
    male: '2',   // Male
    other: '3'   // Other (Transgender)
  };
  const houseMap= {
    ownhouse: '01',
    rented: '02',
  }


  // Map each customer to TUDF format
  const tudfRecords = customers.map((customer) => {
    console.log(customer);
    
    // Construct the customer name
    const customerName = [customer.fname, customer.mname, customer.lname].filter(Boolean).join(' '); // Join name parts

    // Extract and validate fields
    const gender = genderMap[customer.gender.toLowerCase()] || '';
    const ownhouse = houseMap[customer.ownhouse.toLowerCase()] || '';
    const pin = customer.pin || ''; // Fallback to empty string if branchCode is undefined
    const branchCode = customer.brcode || ''; // Fallback to empty string if branchCode is undefined
    const email = customer.email || ''; // Fallback to empty string if email is undefined
    const mobile = customer.mob || '';
    const dob = customer.dob || '';
    const state = customer.state || '';
    // Construct PA segments for address lines
    const addressLines = [
      customer.address1 || '',
      customer.address2 || '',
      customer.city || '',
      customer.state || '',
      customer.country || ''
    ];


    // Construct Aahaar and PAN segments
    const aahaarNumber = customer.aadhaar || ''; // Fallback to empty string if not present
    const panNumber = customer.pan || ''; // Fallback to empty string if not present

    // Format Aahaar and PAN segments
    // Construct the TUDF record; replace with the actual TUDF format required
    return `PN03N010115${customerName}0708${dob}0801${gender}ID03I01010206${aahaarNumber}PT03T010110${mobile}EC03C010170${email}PA03A010140${addressLines}0602${state}0706${pin}0802020902${ownhouse}TL04T0010110BF911100010208ABC BANK03091234567890402020501108081105200809082209201611083101201712071000000130520000140350015017210200260202380410.539031204005200004101042010430104402014505195004602014705500004801N4901MES02**`;
  });

  // Combine header and records into a single string
  return `${tudfHeader}\n${tudfRecords.join('\n')}`;
};
