import React from 'react';
import DashBoard from '../../components/DashBoard';
import FingerPrintForm from './fingerprintForm';

function FingerPrint(props) {
    return (
        <div>
            <DashBoard />
            <section className='flex items-center px-10 justify-center' style={{'paddingTop': '20vh'}}>
 
            <FingerPrintForm />
            </section>
            
        </div>
    );
}

export default FingerPrint;