import React, { useContext, useEffect, useState } from 'react';
import { TransactionsTable } from '../../components/transtable/TransactionTable';
import DashBoard from '../../components/DashBoard';
import { AppContext } from '../../context/appContext';
function DayBook(props) {

    let { baseurl ,sidebarToggle} = useContext(AppContext)
    const [data,setData]=useState([])
    const TABLE_HEAD = ["Code", "Hcode", "Description","Credit", "Debit"];
    const datakey = ['code','hcode','description','credit','debit']
    useEffect(()=>{
        fetch(`${baseurl}receiptpayment/receipt/?start_date=2024-01-11&end_date=2024-04-19&transaction_type=C`).then(response=>response.json()).then((data)=>{console.log(data);setData(data.data)})
    },[])
    console.log(data)
    return (
        <div>
            <DashBoard />
            <div className={`${!sidebarToggle?'ms-64 duration-500': 'ms-0 duration-500'}`}>
            <TransactionsTable TABLE_HEAD={TABLE_HEAD} TABLE_ROWS={data} datakey={datakey}/>
        </div>
        </div>
    );
}

export default DayBook;