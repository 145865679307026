import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Title } from '../../titles/titles';
import Pagination from '../../components/Pagination';
import { AppContext } from '../../context/appContext';
import AlertMessage from '../../components/alert/Alert'; // Adjust the import path as necessary
import DaybookEditTable from './daybookedittable';
import DashBoard from '../../components/DashBoard';

function GoldLoanEditList() {
  const today = new Date().toISOString().split('T')[0];
  
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [goldloan, setGoldloan] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [editItem, setEditItem] = useState(null); // State for the item being edited
  const [updatedItem, setUpdatedItem] = useState({}); // State for updated item values
  const itemsPerPage = 5;
  const { baseurl, sidebarToggle} = useContext(AppContext);

  const getFinancialYear = (date) => {
    const d = new Date(date);
    let year = d.getFullYear();
    const month = d.getMonth();

    if (month < 3) {
      year -= 1;
    }

    return { start: `${year}-04-01`, end: `${year + 1}-03-31` };
  };

  const handleSearch = async () => {
    if (!startDate || !endDate) {
      setValidationError('Please select both start and end dates.');
      setError('');
      setAlertOpen(true);
      return;
    }

    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    if (endDateObj < startDateObj) {
      setValidationError('End date cannot be earlier than the start date.');
      setError('');
      setAlertOpen(true);
      return;
    }

    const { start: financialStart, end: financialEnd } = getFinancialYear(endDate);
    const financialStartDate = new Date(financialStart);
    const financialEndDate = new Date(financialEnd);

    if (startDateObj < financialStartDate || endDateObj > financialEndDate) {
      const alertMessage = `Dates are not within the same financial year.`;
      setValidationError(alertMessage);
      setError('');
      setAlertOpen(true);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(`${baseurl}goldloan/`, {
        params: {
          start_date: startDate,
          end_date: endDate,
          page: currentPage
        }
      });
      console.log(response);
      
      setGoldloan(response.data);
      setTotalItems(response.data.count);
      setError('');
      setValidationError('');
      setAlertOpen(false);
      
    } catch (error) {
      setError(error.response?.data?.error || 'No data Found');
      setGoldloan([]);
      setValidationError('');
      setAlertOpen(true);
    }
    setLoading(false);
  };

  const handleSetActivePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchButtonClick = () => {
    setSearchTriggered(true);
    handleSearch();
  };

  const handleReset = () => {
    setStartDate(today);
    setEndDate(today);
    setSearchTriggered(false);
    setGoldloan([]);
    setError('');
    setTotalItems(0);
    setCurrentPage(1);
    setValidationError('');
    setAlertOpen(false);
    setEditItem(null); // Reset edit item on reset
    setUpdatedItem({});
  };

  useEffect(() => {
    if (searchTriggered) {
      handleSearch();
    }
  }, [currentPage, searchTriggered]);

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    setCurrentPage(1);
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);
    setCurrentPage(1);
  };

  const handleEdit = (item) => {
    setEditItem(item);
    setUpdatedItem({ ...item }); // Initialize updatedItem with current item data
  };

  const handleUpdate = async () => {
    if (!editItem) return;
  
    setLoading(true);
    try {
      // Construct the URL with `trdate` as a query parameter
      const response = await axios.put(
        `${baseurl}testapp/editgoldloan/${editItem.id}/`,
        null, // No body needed if `trdate` is in the URL
        {
          params: {
            trdate: updatedItem.trdate,  // Send trdate as a query parameter
            
            // Add other fields as necessary
          }
        }
      );
  
      // Assuming the response contains the updated item
      setGoldloan(goldloan.map(item => item.id === editItem.id ? response.data : item));
      setEditItem(null);
      setUpdatedItem({});
      setError('');
      setValidationError('');
      setSuccessMessage(response.data.message)
      setAlertOpen(true);

    } catch (error) {
      setError(error.response?.data?.detail || 'Failed to update item');
      setAlertOpen(true);
      setSuccessMessage('')

    }
    setLoading(false);
  };
  return (
    <>
    <DashBoard/>
    <div className={`${!sidebarToggle?'ms-64 duration-500': 'ms-0 duration-500'}`}>

    <div style={{paddingTop : '60'}}>
      <Title title="Gold Loan" />
      <div className="flex w-11/12 ml-auto mr-auto text-left flex-wrap gap-x-4">
        <div className="flex items-center">
          <label className="mr-2">Start Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            className="border rounded px-2 py-1"
          />
        </div>
        <div className="flex items-center">
          <label className="mr-2">End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            className="border rounded px-2 py-1"
          />
        </div>
        <button
          onClick={handleSearchButtonClick}
          className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
        >
          Search
        </button>
        <button
          onClick={handleReset}
          className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
        >
          Reset
        </button>
      </div>
      <AlertMessage
        open={alertOpen}
        setOpen={setAlertOpen}
        message={successMessage || validationError || error}
        color={validationError || error ? 'red' : 'green'}
      />
      {editItem && (
        <div className="p-4 border rounded mb-4 bg-gray-100">
          <h3 className="text-lg font-semibold mb-2">Edit Daybook</h3>
          <label className="block mb-2">
            Date:
            <input
              type="date"
              value={updatedItem.trdate || ''}
              onChange={(e) => setUpdatedItem({ ...updatedItem, trdate: e.target.value })}
              className="border rounded px-2 py-1"
            />
          </label>
          <label className="block mb-2">
            Code:
            <input
              type="text"
              value={updatedItem.customerid || ''}
              
              className="border rounded px-2 py-1"
              readOnly
            />
          </label>
          {/* Add more fields as necessary */}
          <button
            onClick={handleUpdate}
            className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
          >
            Update
          </button>
          <button
            onClick={() => setEditItem(null)}
            className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 ml-2"
          >
            Cancel
          </button>
        </div>
      )}
      {searchTriggered && goldloan.length > 0 && (
        <>
          <DaybookEditTable
            TABLE_ROWS={goldloan}
            HEAD={['Date', 'customerid', 'full_name', 'User Code']}
            datakey={['trdate', 'customerid', 'full_name', 'ucode']}
            onEdit={handleEdit}
          />
          <Pagination
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            activePage={currentPage}
            setActivePage={handleSetActivePage}
          />
        </>
      )}
    </div>
    </div>
    </>
  );
}

export default GoldLoanEditList;
