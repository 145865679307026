import React from 'react';
import DashBoard from '../../components/DashBoard';
import Receipts from './receiptform';

function Receipt(props) {
    return (
        <div>
            <DashBoard/>
            <Receipts/>
        </div>
    );
}
export default Receipt;