import React, { useState,useContext } from 'react';
import DashBoard from '../../components/DashBoard';
import CreateCountryForm from './Country';
import CreateStateForm from './State';
import CreateCityForm from './City';
import { AppContext } from '../../context/appContext';

function RegisterCountry(props) {
    const [selectedOption, setSelectedOption] = useState('countryForm');
    let { sidebarToggle} = useContext(AppContext)
    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <>
        <DashBoard/>
        <div className={`${!sidebarToggle?'ms-64 duration-500': 'ms-0 duration-500'}`}>
        <div >
            <div className='flex justify-around'>
            <select 
                id="dropdown" 
                value={selectedOption} 
                onChange={handleChange}
                className="mt-20 w-1/3 h-7 border mt-1 text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"

            >
                <option value="countryForm">Country</option>
                <option value="stateForm">State</option>
                <option value="cityForm">City</option>
            </select>
            </div>
            {/* Render selected form */}
            {selectedOption === 'countryForm' && <CreateCountryForm />}
            {selectedOption === 'stateForm' && <CreateStateForm />}
            {selectedOption === 'cityForm' && <CreateCityForm />}
        </div>
        </div>
        </>
    );
}

export default RegisterCountry;