import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AppContext } from '../../context/appContext';
import SimpleTable from '../../components/table/SimpleTable';
import AlertMessage from '../../components/alert/Alert';
import DashBoard from '../../components/DashBoard';

const AssignRoleToUser = () => {
  const { baseurl,sidebarToggle, comcode, brcode, ucode, gcode } = useContext(AppContext);

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    setError,
    setValue,
    
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const [tableData, setTableData] = useState([]);
  const [getUser, setGetUser] = useState([]);
  const [getRole, setGetRole] = useState([]);
  const [open, setOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [selectedRoleData, setSelectedRoleData] = useState([]);
  const [roleData, setRoleData] = useState({
    fk_user: '',
    fk_rolename: '',
  });
  const [isUserLocked, setIsUserLocked] = useState(false);


  useEffect(() => {
    const fetchRole = async () => {
      try {
        const response = await fetch(`${baseurl}user/create-role/`);
        if (response.ok) {
          const data = await response.json();
          setGetRole(data);
        } else {
          throw new Error('Failed to fetch roles');
        }
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    fetchRole();
  }, [baseurl]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`${baseurl}user/create-user/`);
        if (response.ok) {
          const data = await response.json();
          setGetUser(data.results.data);
        } else {
          throw new Error('Failed to fetch users');
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUser();
  }, [baseurl]);
  useEffect(() => {
    // Lock or unlock role_name field based on tableData
    if (tableData.length > 0) {
      setIsUserLocked(true);
    } else {
      setIsUserLocked(false);
    }
  }, [tableData]);


  const handleSelectUser = async (e) => {
   
    const [selectedUserId] = e.target.value
    if (!selectedUserId) {
      console.error('Invalid user data');
      return;
    }
    setValue("fk_user", selectedUserId, { shouldValidate: true, shouldDirty: true });
    if (!isUserLocked) {
      setRoleData({
        ...roleData,
        fk_user: selectedUserId,
      });
    } else {
      // Use the existing role name and fk_rolename if already locked
      setRoleData(prev => ({
        ...prev,
        fk_user: selectedUserId,
      }));

    }

    try {
      const response = await fetch(`${baseurl}user/selected-user/${selectedUserId}/`);
      if (response.ok) {
        const data = await response.json();
        setSelectedRoleData(data.data);
      } else {
        throw new Error('Failed to fetch user roles');
      }
    } catch (error) {
      console.error('Error fetching user roles:', error);
    }
  };

  const handleSelectRole = (e) => {
    console.log('getvaluw')
    const selectedRoleName = e.target.value;
    setValue("fk_rolename", selectedRoleName, { shouldValidate: true, shouldDirty: true });

    setRoleData(prev => ({
      ...prev,
      fk_rolename: selectedRoleName,
    }));
  };
  const onSubmit = async () => {
    try {
      if (tableData.length === 0) {
        alert('Please add at least one data entry.');
        return;
      }
      const dataToSend = tableData.map((data) => ({
        ...data,
        comcode,
        ucode,
        brcode,
        gcode,
      }));

      const response = await fetch(`${baseurl}user/assign-role-user/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      const result = await response.json();
      if (response.status === 201) {
        setSuccessMessage(result.message);
        setOpen(true);
        setTableData([]);
        reset();
        setErrorMessage('');
        setSelectedRoleData([]);
      } else if (response.status === 400) {
        for (const [key, value] of Object.entries(result.error)) {
          setError(key, {
            type: 'manual',
            message: value,
          });
        }
        setErrorMessage(result.message);
        setSuccessMessage('');
        setOpen(true);
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      alert('Failed to submit data. Please try again.');
    }
  };

  const handleAdd = () => {
    // Clear existing errors
    clearErrors(['fk_rolename', 'fk_user']);

    // Validate input fields
    if (!roleData.fk_user || !roleData.fk_rolename) {
      setError('fk_user', { type: 'manual', message: 'User ID is required' });
      setError('fk_rolename', { type: 'manual', message: 'Role ID is required' });
      setTimeout(() => {
        clearErrors('fk_user');
        clearErrors('fk_rolename');
      }, 1000);
      return;

    }

    // Normalize the role data for comparison
    const normalizedRoleData = {
      fk_user: String(roleData.fk_user).trim().toLowerCase(),
      fk_rolename: String(roleData.fk_rolename).trim().toLowerCase(),
    };
    const isDuplicateInTableData = tableData.some(item => {
      const normalizedItem = {
        fk_user: String(item.fk_user).trim().toLowerCase(),
        fk_rolename: String(item.fk_rolename).trim().toLowerCase(),
      };
      return normalizedItem.fk_user === normalizedRoleData.fk_user &&
        normalizedItem.fk_rolename === normalizedRoleData.fk_rolename;
    });

    if (isDuplicateInTableData) {
      setError('fk_user', { type: 'manual', message: 'The selected role and page combination already exists in the table.' });
      setTimeout(() => {
        clearErrors('fk_user');
      }, 1000);
      return;
    }

    // Check for duplicates
    const isDuplicate = tableData && selectedRoleData.some(item => {
      // Ensure item.pagenumber and item.role_name are strings
      const normalizedItem = {
        fk_user: String(item.fk_user).trim().toLowerCase(),
        fk_rolename: String(item.fk_rolename).trim().toLowerCase(),
      };
      return normalizedItem.fk_user === normalizedRoleData.fk_user &&
        normalizedItem.fk_rolename === normalizedRoleData.fk_rolename;
    });

    // Debugging: Log whether duplicate was found
    console.log('Is Duplicate:', isDuplicate);

    if (isDuplicate) {
      setError('fk_user', { type: 'manual', message: 'Page Name & Page Number must be unique within the Company.' });
      setTimeout(() => {
        clearErrors('fk_user');
      }, 1000);
      return;
    }

    // If no duplicates, add the data to the table
    setTableData(prevData => [...prevData, roleData]);

    // Clear the roleData state and reset the form for the next entry
    setRoleData({...roleData,
      fk_rolename: '',
    });
    reset();
  };


  const handleRemoveInterest = (index) => {
    const updatedInterestList = [...tableData];
    updatedInterestList.splice(index, 1);
    setTableData(updatedInterestList);
  };

  const handleDeleteSelectedRole = async (index) => {
    try {
      const roleIdToDelete = selectedRoleData[index].id;
      const response = await fetch(`${baseurl}user/delete-user/${roleIdToDelete}/`, {
        method: 'DELETE',
      });

      if (response.ok) {
        const updatedSelectedRoleData = [...selectedRoleData];
        updatedSelectedRoleData.splice(index, 1);
        setSelectedRoleData(updatedSelectedRoleData);
      } else {
        throw new Error('Failed to delete role data');
      }
    } catch (error) {
      console.error('Error deleting role data:', error);
      alert('Failed to delete role data. Please try again.');
    }
  };

  return (
    <>
    <DashBoard/>
    <div className={`${!sidebarToggle?'ms-64 duration-500': 'ms-0 duration-500'}`}>
    <div className='pt-20'>
      {successMessage && open && <AlertMessage open={open} setOpen={setOpen} message={successMessage}/>}
      {errorMessage && open && <AlertMessage open={open} color={'red'} setOpen={setOpen} message={errorMessage}/>}
      <h1 className=" font-semibold mb-4 text-center">Assign Role To User</h1>

      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-wrap -mx-2 w-10/12 items-center ml-auto mr-auto">
        <div className="w-full lg:w-1/2 md:w-1/2 px-2 mb-4">
          <label className="block text-gray-700">Select User</label>

          {console.log('hjj',getUser)}
          <select
            name='fk_user'
            onChange={handleSelectUser}
            className="form-select mt-1 block w-full border-gray-300 border"
            disabled={isUserLocked}
          >
            <option value="">Select User</option>
            {getUser && getUser.map((user, index) => (
              <option key={user.id} value={user.id}>
                {user.id} - {user.username}
              </option>
            ))}
          </select>
          {errors.fk_user && <span className="text-red-500 mt-1 text-xs italic">{errors.fk_user.message}</span>}

        </div>
        <div className="w-full lg:w-1/2 md:w-1/2 px-2 mb-4">
          <label className="block text-gray-700">Select Role</label>
          <select
          name='fk_rolename'
            value={roleData.fk_rolename} // Ensure this corresponds to the state
            onChange={handleSelectRole}
            className="form-select mt-1 block w-full border-gray-300 border"
          >
            <option value="">Select Role</option>
            {getRole && getRole.map((role, index) => (
              <option key={`${role.role_name} - ${index}`} value={role.role_name}>
                {role.role_name}
              </option>
            ))}
          </select>
          {errors.fk_rolename && <span className="text-red-500 mt-1 text-xs italic">{errors.fk_rolename.message}</span>}
        </div>
        <div className='flex justify-start w-full'>
          <div className="px-2 mb-4 mt-6">
            <button type="button" onClick={handleAdd} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
              Add
            </button>
          </div>

          <div className="px-2 mb-4 mt-6">
            <button type="submit" className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
              Submit
            </button>
          </div>
        </div>
      </form>

      <div className='flex h-40'>
        {selectedRoleData.length > 0 && (
          <div className="mt-8 w-1/2 h-40">
            <h3 className="text-xl font-semibold mb-4 text-center">Selected Role Data</h3>
            <SimpleTable
              columns={['Role Name', 'delete']}
              data={selectedRoleData}
              datakey={['fk_rolename']}
              height='h-44'
              deleteElement={true}
              setArray={setSelectedRoleData}
              deleteEle={handleDeleteSelectedRole}
            />
          </div>
        )}

        <div className="mt-8 w-1/2">
          <h3 className="text-xl font-semibold mb-4 text-center">List</h3>
          <SimpleTable
            columns={['User ID', 'Role Name', 'Delete']}
            data={tableData}
            datakey={['fk_user', 'fk_rolename']}
            height='h-44'
            deleteElement={true}
            setArray={setTableData}
            deleteEle={handleRemoveInterest}
          />
        </div>
      </div>
    </div>
    </div>
    </>
  );
};

export default AssignRoleToUser;
