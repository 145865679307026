import React, { useContext, useState } from "react";
import {
  CaptureFinger,
  GetConnectedDeviceList,
  GetMorFinAuthInfo,
  GetTemplate,
  VerifyFinger,

} from "../goldloan/morfinauth";
import { IconButton } from "@material-tailwind/react";


// import {
//   IsDeviceConnected,
//   CaptureFinger,
//   GetConnectedDeviceList,
//   InitDevice,
//   GetMorFinAuthInfo,
//   GetTemplate,
//   VerifyFinger,
// } from "./morfinauth";
import { Button } from "@material-tailwind/react";
import axios from "axios";
import { AppContext } from "../../context/appContext";

function FingerPrintForm(props) {
  let { baseurl} = useContext(AppContext);
  
  
  const [userName, setUserName] = useState('');
  
  const [error, setError] = useState('');
  const [selectedUser, setSelectedUser] = useState()
  let [verifyProcess, setVerifyProcess] = useState(false);
  let [verifyMessage, setVerifyMessage] = useState("");

  let [fin, setFin] = useState();
  let capture = () => {
    setVerifyMessage('')
    let list = GetConnectedDeviceList();

    let connectedDvc = list.data.ErrorDescription.split(":");
    connectedDvc = connectedDvc[1].split(",")[0];
    let clientKey = "";
    GetMorFinAuthInfo(connectedDvc, clientKey);

    let result = CaptureFinger(60, 10);
    // console.log(result)

    if (result.httpStaus) {
      setFin(CaptureFinger(60, 10));
    }
  };
  


async function addFp(data) {
  try {
   
    
    console.log('Data to be sent:', data);
    
    // Make the POST request
    const response = await axios.post(`${baseurl}user/userfp/`, data);
    
    // Check if the request was successful
    if (response.data.status_code === 201) {
      // Update state or perform any actions based on the success
      setFin();
      setVerifyMessage("Fingerprint added successfully");
    } else {
      console.log('Unexpected status code:', response.data.status_code);
      // Handle other status codes or errors here
    }
  } catch (error) {
    // Handle any errors that occur during the request
    console.error('Error adding fingerprint:', error);
  }
}




  const addFingerPrint = async () => {
    let template = GetTemplate(0);
     // Prepare the data payload
     let data = {
      fp: template.data.ImgData,
      fk_user: selectedUser.id
    };
    let res1;
  try {
    // Make the API request
    res1 = await axios.get(`${baseurl}user/userfp_by_id/${selectedUser.id}`);
    
    // Handle the response if successful
    console.log('User data:', res1.data);

  } catch (error) {
    // Handle any errors that occur during the request
    console.log('Error fetching user data:', error);
    
    if (error.response) {
        // The request was made, and the server responded with a status code that falls out of the range of 2xx
        console.log('Response error:', error.response.data);
        if(error.response.status === 404){
          addFp(data)
          return
          // setError(error.response.data.errors)

        }
    } 
    
   }

       // fingerprint list of user
       let fp = res1.data.data;
      
       // verifying fingerprint
      for (let i = 0; i < fp.length; i++) {
      // device built-in function to verify fingerprint
      let res = VerifyFinger(template.data.ImgData, fp[i].fp, 0);

      if (res.httpStaus) {
        console.log(res.data.Status)
        // breaks the loop when one of the fingerprint matches
        if (res.data.Status) {
          console.log('fingerprint already exists')
          // setVerify(false);
          setVerifyProcess(false);
          setVerifyMessage("Fingerprint Already Exists.");
          return
        }
        //  else {
        //   if (res.data.ErrorCode != "0") {
        //     // setVerify(true);
        //     setVerifyProcess(false);
        //     setVerifyMessage(res.data.ErrorDescription);
        //   } else {
        //     // setVerify(true);
        //     setVerifyProcess(false);
        //     // setVerifyMessage("Finger not matched");
        //    addFp(data)
        //       setError('')
        //   }
        // }
      } else {
        // setVerify(true);
        setVerifyProcess(false);
        setVerifyMessage(res.err);
      }
    }

    addFp(data)
  };


 

  const handleSearch = async () => {
    // setLoading(true);
    try {
      const response = await axios.get(`${baseurl}user/get-user/`, {
        params: {
          // comcode: null,
          // brcode: null,
          username: userName,
          // page: currentPage
        }
      });
      console.log(response.data.data)
      if(response.status === 200){

        setSelectedUser(response.data.data);
      setError('');

      }
      // setTotalItems(response.data.count);
      // setError('');
    } catch (error) {
      setError(error.response.data.errors);
      setSelectedUser('');
      // setUsers([]);
      console.log(error)
    }
    // setLoading(false);
  };
 

 
  return (
    <>
   
   
       <div className="flex flex-col">
        <div className="flex">
          
          <input
            type="text"
            value={userName}
            onChange={(e)=>setUserName(e.target.value)}
            name="Username"
            className="form-control w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            placeholder="Username"
          />
          <IconButton
          className="ms-2"
        
           
            onClick={handleSearch}
            >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
            </svg>

          </IconButton>
            </div>
          <div className="flex flex-col mt-4">

          {selectedUser&&

        <><span>UID: {selectedUser.id}</span>
          <span>USERNAME: {selectedUser.username}</span>
          <span>EMAIL: {selectedUser.email}</span>
          <span>PHONE: {selectedUser.phone}</span></>
          }
          </div>
          <div style={{color:'green'}}>{verifyMessage&&verifyMessage}</div>
          {error&&<span style={{color:'red'}}>{error}</span>}
        </div>
         <div className="flex  py-10 px-10 justify-center items-center">
                  <div className="flex justify-center mt-20 items-center w-52">

                        <div className="size-20 border flex mb-2 justify-center items-center border-black">
                          {fin && fin.data.ErrorCode === "0" ? (
                            <img
                              className="size-16"
                              src={`data:image/bmp;base64,${fin.data.BitmapData}`}
                            />
                          ) : (
                            <span className="text-sm">fingerprint</span>
                          )}
                        </div>
                  </div>
   

     

                <div className="flex flex-col px-5 mt-20">

                      <Button disabled={selectedUser?false:true} size="sm" className="mb-2" onClick={capture}>
                          Capture
                        </Button>
                        <Button disabled={fin?false:true} size="sm" onClick={addFingerPrint}>
                          Add FingerPrint
                        </Button>
                </div>
      
    </div>
        
  </> );

}

export default FingerPrintForm;
