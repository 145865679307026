// import React, { useContext } from 'react';
// import { LoanCloseContext } from './LoanCloseContext'; // Ensure the correct path

// const LoandetailModal = ({ isOpen, onClose}) => {
//     const {sajdata } = useContext(LoanCloseContext);
    
//     if (!isOpen) return null;
    
//     // Define headers and corresponding values
//     const headers = [
//         'Customer ID',
//         'Customer Name',
//         'Date of Birth',
//         'Gender',
//         'Aadhaar No'
//     ];
//     console.log("ffhyy",sajdata.calucated_data.length);
    
    
//     const values = [
//         sajdata?.calucated_data?.[0].trdate || 'N/A',
//         sajdata?.calucated_data?.credit || 'N/A',
//         sajdata?.calucated_data?.debit || 'N/A',
//         sajdata?.calucated_data?.intrestamt || 'N/A',
//         sajdata?.calucated_data?.Balamount || 'N/A'
//     ];

//     return (
//         <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
//             <div className="relative w-10/12 bg-white p-8 rounded-lg mt-20 mb-20">
//                 <button 
//                     className="absolute top-5 right-5 text-right text-black-900" 
//                     onClick={onClose}
//                 >
//                     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
//                         <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
//                     </svg>
//                 </button>
//                 <h2 className="text-xl font-bold mb-4 text-center">Loan Details</h2>
//                 <div className="overflow-y-auto w-full">
//                     <table className="w-full divide-y divide-gray-200 border border-solid border-gray-300 rounded">
//                         <thead className="bg-gray-100">
//                             <tr className="h-10">
//                                 {headers.map((header, index) => (
//                                     <th key={index} className="text-sm font-medium text-gray-700">{header}</th>
//                                 ))}
//                             </tr>
//                         </thead>
//                         <tbody className="bg-white divide-y divide-gray-200">
//                             <tr className="h-10 text-center">
//                                 {values.map((value, index) => (
//                                     <td key={index} className="text-sm text-gray-700">{value}</td>
//                                 ))}
//                             </tr>
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default LoandetailModal;



import React, { useContext } from 'react';
import { LoanCloseContext } from './LoanCloseContext'; // Ensure the correct path

const LoandetailModal = ({ isOpen, onClose }) => {
  const { sajdata } = useContext(LoanCloseContext);

  if (!isOpen) return null;

  // Define headers
  const headers = [
    'Transaction Date',
    'Credit',
    'Debit',
    'Interest',
    'Balance'
  ];

  // Extract values from calucated_data array
  const transactions = sajdata?.calucated_data || [];

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50" onClick={onClose}>
      <div className="relative w-10/12 bg-white p-8 rounded-lg mt-20 mb-20 h-96">
        <button
          className="absolute top-5 right-5 text-right text-black-900"
          onClick={onClose}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="text-xl font-bold mb-4 text-center">Calculated</h2>
        <div className="w-full h-72 overflow-y-scroll">
          <table className="w-full divide-y divide-gray-200 border border-solid border-gray-300 rounded">
            <thead className="bg-gray-100">
              <tr className="h-10">
                {headers.map((header, index) => (
                  <th key={index} className="text-sm font-medium text-gray-700">{header}</th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {transactions.map((transaction, index) => (
                <tr key={index} className="h-10 text-center">
                  <td className="text-sm text-gray-700">{transaction.trdate || 'N/A'}</td>
                  <td className="text-sm text-gray-700">{transaction.credit || 0}</td>
                  <td className="text-sm text-gray-700">{transaction.debit || 0}</td>
                  <td className="text-sm text-gray-700">{transaction.intrest || 'N/A'}</td>
                  <td className="text-sm text-gray-700">{transaction.Balance || 0}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LoandetailModal;

