import React, {useContext} from 'react';
import DashBoard from '../../components/DashBoard'
import { DefaultStepper } from '../../components/stepper/stepp';
import { Title } from '../../titles/titles';
import LoanProvider from "../../context/loanContext";
import { AppContext } from '../../context/appContext';



function GoldLoan(props) {
  const {sidebarToggle} = useContext(AppContext);

    return (
        <div>
          {/* dashboard */}
            <DashBoard />
          {/* end of dashboard */}
          <div className={`${!sidebarToggle?'ms-64 duration-500': 'ms-0 duration-500'}`}>

           <section className='w-full md:mx-auto'>

              <Title title="Gold Loan"/>

          {/* loan context */}
              <LoanProvider>

                    {/* stepper for goldloan */}
                    <DefaultStepper />
                    {/* end of stepper */}

              </LoanProvider>
          {/* end of loan context */}
            </section>
            
        </div>
        </div>
    );
}

export default GoldLoan;