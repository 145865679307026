import React from "react";
import Test from "./testform";

function Tests(props){
    return(
        <div>
            <Test/>
        </div>
    )
}
export default Tests