import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AppContext } from '../../context/appContext';
import SimpleTable from '../../components/table/SimpleTable';
import AlertMessage from '../../components/alert/Alert';

const AssignRole = () => {
  const { baseurl,sidebarToggle, comcode, brcode, ucode, gcode } = useContext(AppContext);

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    setError,
    setValue,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const [tableData, setTableData] = useState([]);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [getRole, setGetRole] = useState([]);
  const [open, setOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [selectedRoleData, setSelectedRoleData] = useState([]);
  const [roleData, setRoleData] = useState({
    role_name: '',
    pagenumber: '',
    pagename: '',
    fk_permissionid: '',
    fk_rolename: ''
  });

  const [isRoleNameLocked, setIsRoleNameLocked] = useState(false);

  useEffect(() => {
    // Fetch page numbers
    const fetchPageNumbers = async () => {
      try {
        const response = await fetch(`${baseurl}user/get-page/`);
        if (response.ok) {
          const data = await response.json();
          setPageNumbers(data.results.data);
        } else {
          throw new Error('Failed to fetch page numbers');
        }
      } catch (error) {
        console.error('Error fetching page numbers:', error);
      }
    };

    fetchPageNumbers();
  }, [baseurl]);

  useEffect(() => {
    // Fetch role names
    const fetchRoleName = async () => {
      try {
        const response = await fetch(`${baseurl}user/create-role/`);
        if (response.ok) {
          const data = await response.json();
          setGetRole(data);
        } else {
          throw new Error('Failed to fetch Role name');
        }
      } catch (error) {
        console.error("Error fetching Role name:", error);
      }
    };
    fetchRoleName();
  }, [baseurl]);

  useEffect(() => {
    // Lock or unlock role_name field based on tableData
    if (tableData.length > 0) {
      setIsRoleNameLocked(true);
    } else {
      setIsRoleNameLocked(false);
    }
  }, [tableData]);

  const handleSelectRole = async (e) => {
    const [selectedRoleName, selectedRoleid] = e.target.value.split('|');
    setValue("role_name", selectedRoleName, { shouldValidate: true, shouldDirty: true });
    setValue("fk_rolename", selectedRoleid, { shouldValidate: true, shouldDirty: true });

    if (!selectedRoleName || !selectedRoleid) {
      console.error('Invalid role data');
      return;
    }

    if (!isRoleNameLocked) {
      setRoleData({
        ...roleData,
        role_name: selectedRoleName,
        fk_rolename: selectedRoleid,
      });
    } else {
      // Use the existing role name and fk_rolename if already locked
      setRoleData(prev => ({
        ...prev,
        role_name: selectedRoleName,
        fk_rolename: selectedRoleid,
      }));
    }

    try {
      const response = await fetch(`${baseurl}user/selected-role/${selectedRoleName}/`);
      if (response.ok) {
        const data = await response.json();
        setSelectedRoleData(data);
        console.log(roleData.role_name, 'asdas');
      } else {
        throw new Error('Failed to fetch role data');
      }
    } catch (error) {
      console.error('Error fetching role data:', error);
    }
  };

  const onSubmit = async () => {
    try {
      if (tableData.length === 0) {
        alert('Please add at least one data entry.');
        return;
      }
      const dataToSend = tableData.map((data) => ({
        ...data,
        comcode,
        ucode,
        brcode,
        gcode,
      }));

      const response = await fetch(`${baseurl}user/assign-role-page/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      const result = await response.json();
      console.log(result);
      if (response.status === 201) {
        setSuccessMessage(result.message);
        setErrorMessage('')
        setOpen(true);
        setTableData([]);
        reset();
        setErrorMessage('');
        setSelectedRoleData([]);
        setIsRoleNameLocked(false); // Reset role name lock after successful submission
      } else if (response.status === 400) {
        for (const [key, value] of Object.entries(result.error)) {
          setError(key, {
            type: 'manual',
            message: value,
          });
        }
        setErrorMessage(result.message);
        setSuccessMessage('');
        setOpen(true);
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      alert('Failed to submit data. Please try again.');
    }
  };

  const handleAdd = () => {
    clearErrors(['role_name', 'pagenumber', 'pagename', 'fk_permissionid', 'fk_rolename']);

    // Validate input fields
    if (!roleData.role_name || !roleData.pagenumber || !roleData.pagename || !roleData.fk_permissionid || !roleData.fk_rolename) {
      setError('role_name', { type: 'manual', message: 'Role Name is required' });
      setError('pagenumber', { type: 'manual', message: 'Page Number is required' });
      setError('pagename', { type: 'manual', message: 'Page Details are required' });
      setError('fk_permissionid', { type: 'manual', message: 'Permission ID is required' });
      setError('fk_rolename', { type: 'manual', message: 'Role Name (FK) is required' });
      setTimeout(() => {
        clearErrors('role_name');
        clearErrors('pagenumber');
        clearErrors('pagename');
        clearErrors('fk_permissionid');
        clearErrors('fk_rolename');
      }, 1000);
      return;
    }

    // Debugging: Log roleData and selectedRoleData
    console.log('Role Data:', roleData);
    console.log('Selected Role Data:', selectedRoleData);

    // Normalize data for comparison
    const normalizedRoleData = {
      role_name: String(roleData.role_name).trim().toLowerCase(),
      pagenumber: String(roleData.pagenumber).trim().toLowerCase(),
    };
    const isDuplicateInTableData = tableData.some(item => {
      const normalizedItem = {
        role_name: String(item.role_name).trim().toLowerCase(),
        pagenumber: String(item.pagenumber).trim().toLowerCase(),
      };
      return normalizedItem.role_name === normalizedRoleData.role_name &&
        normalizedItem.pagenumber === normalizedRoleData.pagenumber;
    });

    if (isDuplicateInTableData) {
      setError('pagenumber', { type: 'manual', message: 'The selected role and page combination already exists in the table.' });
      setTimeout(() => {
        clearErrors('pagenumber');
      }, 1000);
      return;
    }

    // Check for duplicates
    const isDuplicate = tableData && selectedRoleData.some(item => {
      // Ensure item.pagenumber and item.role_name are strings
      const normalizedItem = {
        role_name: String(item.role_name).trim().toLowerCase(),
        pagenumber: String(item.pagenumber).trim().toLowerCase(),
      };
      return normalizedItem.role_name === normalizedRoleData.role_name &&
        normalizedItem.pagenumber === normalizedRoleData.pagenumber;
    });

    // Debugging: Log whether duplicate was found
    console.log('Is Duplicate:', isDuplicate);

    if (isDuplicate) {
      setError('pagenumber', { type: 'manual', message: 'Page Name & Page Number must be unique within the Company.' });
      setTimeout(() => {
        clearErrors('pagenumber');
      }, 1000);
      return;
    }

    // If no duplicates, add the data to the table
    setTableData(prevData => [...prevData, roleData]);
    setRoleData({
      ...roleData,
      pagenumber: '',
      pagename: '',
      fk_permissionid: '',
    }); // Clear other fields for next entry
    reset();
  };

  const handleChangeRole = (e) => {
    const { name, value } = e.target;
    setRoleData({ ...roleData, [name]: value });
  };

  const handleRemoveInterest = (index) => {
    const updatedInterestList = [...tableData];
    updatedInterestList.splice(index, 1);
    setTableData(updatedInterestList);
  };

  const handleChoosePage = (e) => {
    const selectedOption = e.target.value;

    if (selectedOption) {
      const [selectedPagenumber, selectedPagedetails, selectedPageId] = selectedOption.split('|');

      setRoleData({
        ...roleData,
        pagenumber: selectedPagenumber,
        pagename: selectedPagedetails,
        fk_permissionid: selectedPageId,
      });
    } else {
      setRoleData({
        ...roleData,
        pagenumber: '',
        pagename: '',
        fk_permissionid: '',
      });
    }
  };

  const handleDeleteSelectedRole = async (index) => {
    try {
      const roleIdToDelete = selectedRoleData[index].id;
      const response = await fetch(`${baseurl}user/delete-role/${roleIdToDelete}/`, {
        method: 'DELETE',
      });

      if (response.ok) {
        const updatedSelectedRoleData = [...selectedRoleData];
        updatedSelectedRoleData.splice(index, 1);
        setSelectedRoleData(updatedSelectedRoleData);
      } else {
        throw new Error('Failed to delete role data');
      }
    } catch (error) {
      console.error('Error deleting role data:', error);
      alert('Failed to delete role data. Please try again.');
    }
  };

  return (
    <div className={`${!sidebarToggle?'ms-64 duration-500': 'ms-0 duration-500'}`}>
    <div className='pt-20 '>
      {successMessage && open && <AlertMessage open={open} setOpen={setOpen} message={successMessage}/>}
      {errorMessage && open && <AlertMessage open={open} color={'red'} setOpen={setOpen} message={errorMessage}/>}

      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-wrap -mx-2  w-10/12 items-center ml-auto mr-auto">
        <div className="w-full lg:w-1/2 md:w-1/2 px-2 mb-4">
          <label className="block text-gray-700">Role Name</label>
          {console.log('role', roleData.role_name)}
          <select
            name='role_name'
            value={`${roleData.role_name}|${roleData.fk_rolename}`}  // Ensure this matches option value format
            onChange={handleSelectRole}
            disabled={isRoleNameLocked}
            className="form-select mt-1 block w-full border-gray-300 border"
          >
            <option value="">Select Role</option>
            {getRole.map((role) => (
              <option key={role.id} value={`${role.role_name}|${role.id}`}>
                {role.role_name}
              </option>
            ))}
          </select>
            </div>
          <div className="w-full lg:w-1/2 md:w-1/2 px-2 mb-4">
            <label className="block text-gray-700">Choose Page</label>
            <select
              onChange={handleChoosePage}
              className="form-select mt-1 block w-full border-gray-300 border"
            >
              <option value="">Select Page</option>
              {pageNumbers.map((pageNumber) => (
                <option key={pageNumber.id} value={`${pageNumber.pagenumber}|${pageNumber.pagename}|${pageNumber.id}`}>
                  {pageNumber.pagenumber} - {pageNumber.pagename}
                </option>
              ))}
            </select>
            {errors.choose && <span className="text-red-500 mt-1 text-xs italic">{errors.choose.message}</span>}
          </div>

          <div className="w-full lg:w-1/2 md:w-1/2 px-2 mb-4">
            <label className="block text-gray-700">Page Number</label>
            <input
              type="text"
              {...register('pagenumber')}
              className="form-input mt-1 block w-full border-gray-300 border"
              value={roleData.pagenumber}
              onChange={handleChangeRole}
            />
            {errors.pagenumber && <span className="text-red-500 mt-1 text-xs italic">{errors.pagenumber.message}</span>}
          </div>

          <div className="hidden w-full lg:w-1/2 md:w-1/2 px-2 mb-4">
            <label className="block text-gray-700">Permission ID</label>
            <input
              type="number"
              {...register('fk_permissionid')}
              className="form-input mt-1 block w-full border-gray-300 border"
              value={roleData.fk_permissionid}
              onChange={handleChangeRole}
            />
            {errors.fk_permissionid && <span className="text-red-500 mt-1 text-xs italic">{errors.fk_permissionid.message}</span>}
          </div>

          <div className="w-full lg:w-1/2 md:w-1/2 px-2 mb-4">
            <label className="block text-gray-700">Page Name</label>
            <input
              type="text"
              readOnly
              {...register('pagename')}
              className="form-input mt-1 block w-full border-gray-300 border"
              value={roleData.pagename}
            />
            {errors.pagename && <span className="text-red-500 mt-1 text-xs italic">{errors.pagename.message}</span>}
          </div>

          <div className="hidden w-full lg:w-1/4 md:w-1/4 px-2 mb-4">
            <label className="block text-gray-700">Role Name (FK)</label>
            <input
              type="text"
              readOnly
              {...register('fk_rolename')}
              className="form-input mt-1 block w-full border-gray-300 border"
              value={roleData.fk_rolename}
            />
            {errors.fk_rolename && <span className="text-red-500 mt-1 text-xs italic">{errors.fk_rolename.message}</span>}
          </div>

          <div className='flex justify-start w-full'>
            <div className="px-2 mb-4 mt-6">
              <button type="button" onClick={handleAdd} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                Add Role
              </button>
            </div>

            <div className=" px-2 mb-4 mt-6">
              <button type="button" onClick={handleSubmit(onSubmit)} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                Submit
              </button>
            </div>
          </div>
      </form>

      <div className='flex h-40'>
        {selectedRoleData.length > 0 && (
          <div className="mt-8 w-1/2 h-40">
            <h3 className="text-xl font-semibold mb-4 text-center">Selected Role Data</h3>
            <SimpleTable
              columns={['pagenumber', 'pagename']} // Adjust columns as per your requirement
              data={selectedRoleData}
              datakey={['pagenumber', 'pagename']}
              height='h-44'
              deleteElement={true} // Set to true if you want delete functionality
              setArray={setSelectedRoleData} // If you want to update data
              deleteEle={handleDeleteSelectedRole} // Function to handle delete action
            />
          </div>
        )}

        {/* Display table */}
        <div className="mt-8 w-1/2">
          <h3 className="text-xl font-semibold mb-4 text-center">Newly added Role Data</h3>
          <SimpleTable
            columns={['role_name', 'pagenumber', 'pagename', 'delete']}
            data={tableData}
            datakey={['role_name', 'pagenumber', 'pagename']}
            height='h-44'
            deleteElement={true}
            setArray={setTableData}
            deleteEle={handleRemoveInterest}
          />
        </div>
      </div>
    </div>
    </div>
  );
};

export default AssignRole;
