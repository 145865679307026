import React from 'react';
import DashBoard from '../../components/DashBoard';
import AddBankForm from './addbankform';

function Bank(props) {
    return (
        <div>
           
            <DashBoard />
            <AddBankForm />
        </div>
    );
}

export default Bank;