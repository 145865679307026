import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Title } from '../../titles/titles';
import Pagination from '../../components/Pagination';
import { AppContext } from '../../context/appContext';
import DaybookListTable from './daybooktable';
import AlertMessage from '../../components/alert/Alert'; // Adjust the import path as necessary
import '../customer/customer.css'

function DaybookList() {
  const today = new Date().toISOString().split('T')[0];

  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [daybooks, setDaybooks] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTriggered, setSearchTriggered] = useState(false); // Track search state
  const [validationError, setValidationError] = useState(''); // Track validation errors
  const [alertOpen, setAlertOpen] = useState(false); // Manage alert visibility
  const itemsPerPage = 5;
  const { comcode, brcode,baseurl,sidebarToggle } = useContext(AppContext);

  const getFinancialYear = (date) => {
    const d = new Date(date);
    let year = d.getFullYear();
    const month = d.getMonth();

    if (month < 3) {
      year -= 1;
    }

    return { start: `${year}-04-01`, end: `${year + 1}-03-31` };
  };

  const handleSearch = async () => {
    if (!startDate || !endDate) {
      setValidationError('Please select both start and end dates.');
      setError('');
      setAlertOpen(true);
      setDaybooks([]);

      return;
    }

    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    if (endDateObj < startDateObj) {
      setValidationError('End date cannot be earlier than the start date.');
      setError('');
      setAlertOpen(true);
      setDaybooks([]);

      return;
    }

    const { start: financialStart, end: financialEnd } = getFinancialYear(endDate);
    const financialStartDate = new Date(financialStart);
    const financialEndDate = new Date(financialEnd);

    if (startDateObj < financialStartDate || endDateObj > financialEndDate) {
      const alertMessage = `Dates are not in financial year.`;
      setValidationError(alertMessage);
      setError('');
      setAlertOpen(true);
      setDaybooks([]);

      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(`${baseurl}accounting/get-daybook/${comcode}/${brcode}/`, {
        params: {
          start_date: startDate,
          end_date: endDate,
          page: currentPage
        }
      });
      setDaybooks(response.data.results.data);
      setTotalItems(response.data.count);
      setError('');
      setValidationError('');
      setAlertOpen(false); // Close alert if data fetch is successful
    } catch (error) {
      console.log(error.response);
      setError(error.response?.data?.error || 'No data Found');
      setDaybooks([]);
      setValidationError('');
      setAlertOpen(true);
    }
    setLoading(false);
  };

  const handleSetActivePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchButtonClick = () => {
    setSearchTriggered(true);
    handleSearch();
  };

  const handleReset = () => {
    setStartDate(today);
    setEndDate(today);
    setSearchTriggered(false);
    setDaybooks([]);
    setError('');
    setTotalItems(0);
    setCurrentPage(1);
    setValidationError('');
    setAlertOpen(false);
  };

  useEffect(() => {
    if (searchTriggered) {
      handleSearch();
    }
  }, [currentPage, searchTriggered]);

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    setCurrentPage(1);
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);
    setCurrentPage(1);
  };

  return (
    <div className={`${!sidebarToggle?'ms-64 duration-500': 'ms-0 duration-500'}`}>
    <div className='pt-20'>
    <div>
      <Title title="Daybook" />
      <div className="flex w-11/12 ml-auto mr-auto text-left flex-wrap gap-x-4">
        <div className="flex items-center">
          <label className="mr-2">Start Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            className="border rounded px-2 py-1"
          />
        </div>
        <div className="flex items-center">
          <label className="mr-2">End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            className="border rounded px-2 py-1"
          />
        </div>
        <button
          onClick={handleSearchButtonClick}
          className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
        >
          Search
        </button>
        <button
          onClick={handleReset}
          className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
        >
          Reset
        </button>
      </div>
      {loading && <div className="spinner"></div>} {/* loading spinner */}

      <AlertMessage
        open={alertOpen}
        setOpen={setAlertOpen}
        message={validationError || error}
        color={validationError || error ? 'red' : 'green'}
      />
      {searchTriggered && daybooks.length > 0 && (
        <>
          <DaybookListTable
            TABLE_ROWS={daybooks}
            HEAD={['Date', 'Code', 'Name', 'Description', 'Mode', 'Credit', 'Debit', 'User Code']}
            datakey={['trdate', 'code', 'name', 'description', 'mode', 'credit', 'debit', 'ucode']}
          />
          <Pagination
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            activePage={currentPage}
            setActivePage={handleSetActivePage}
          />
        </>
      )}
    </div>
    </div>
    </div>
  );
}

export default DaybookList;
