import React, { useState } from 'react';
import { Tooltip, Button } from "@material-tailwind/react";
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';


const CopyText = ({textToCopy}) => {
//   const [textToCopy, setTextToCopy] = useState('This is the text to copy');
  const [copySuccess, setCopySuccess] = useState('');
const [copy, setCopy] = useState(false)
  const handleCopyClick = () => {
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        setCopySuccess('Copied');
        setCopy(true)
      },
      (err) => {
        setCopySuccess('Failed to copy text.');
        console.error('Error copying text: ', err);
      }
    );
  };

  return (
    <div className='flex'>
      <p className='ps-2'>{textToCopy}</p>
      <Tooltip content={`${copy?copySuccess:'Copy'}`} placement="top">

      <button className='px-2' onClick={handleCopyClick}>     
         <ClipboardDocumentIcon className="w-5 h-5"/>
      </button>
      </Tooltip>
      {/* {copySuccess && <p>{copySuccess}</p>} */}
    </div>
  );
};

export default CopyText;
