import React from "react";
import { Alert, Button } from "@material-tailwind/react";

function AlertMessage({ message, open, setOpen, color }) {
  const close = () => {
    open &&
      setTimeout(() => {
        setOpen(false);
      }, 8000);
  };
  close();

  return (
    <div style={{display: 'flex', justifyContent: 'end'}}>
      <Alert style={{width: '50vw'}}

        variant="outlined"
        open={open}
        onClose={() => setOpen(false)}
        animate={{
          mount: { y: 0 },
          unmount: { y: 100 },
        }}
        color={color ? color : "green"}
        // className='border-green-600'
        // className="relative flex w-full px-4 py-4 text-base text-gray-900 border border-green-900 rounded-lg font-regular"
      >
        {message}
      </Alert>
    </div>
  );
}

export default AlertMessage;
