import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Title } from '../../titles/titles';
import Pagination from '../../components/Pagination';
import { AppContext } from '../../context/appContext';
import DashBoard from '../../components/DashBoard';
import AadhaarTable from './aadhaarTable';
import './customer.css'
function AadhaarList() {
  const [fullName, setFullName] = useState('');
  const [aadhaar, setAadhaar] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFormChanged, setIsFormChanged] = useState(false); // Track form changes
  const itemsPerPage = 7;
  const { baseurl} = useContext(AppContext);

  // Function to fetch and update customer data based on form inputs
  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseurl}customer/aadhaar-filter/`, {
        params: {
          full_name: fullName,
          page: currentPage
        }
      });
      setAadhaar(response.data.results.data);
      setTotalItems(response.data.count);
      setError('');
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred');
      setAadhaar([]);
    }
    setLoading(false);
  };


  // Handles form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    setCurrentPage(1); // Reset to the first page
    setIsFormChanged(true); // Indicate that the form has changed
  };

  const handleReset = () => {
    setFullName('');
    setIsFormChanged(false); // Indicate that the form has not changed
    setAadhaar([]); // Clear customer data
  };

  // Handles page change
  const handleSetActivePage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setIsFormChanged(true); // Indicate that the page has changed
  };

  // Fetch data when form is submitted, page is changed, or form inputs are changed
  useEffect(() => {
    if (isFormChanged) {
      handleSearch();
      setIsFormChanged(false); // Reset form changed state after data is fetched
    }
  }, [currentPage, isFormChanged]);

  // Fetch all data for export
  return (
    <div>
      <DashBoard />
      <h1 className='pt-20 text-2xl text-center'>Aadhaar Details</h1>
      <form onSubmit={handleSubmit} className="flex w-11/12 ml-auto mr-auto mt-5 text-left flex-wrap gap-x-4">
        <div className="flex items-center">
          <label className="mr-2">Customer Name:</label>
          <input
            type="text"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            className="border rounded px-2 py-1"
          />
        </div>
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
          Submit
        </button>
        <button type="button" onClick={handleReset} className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 ml-4">
          Reset
        </button>
      </form>
      {loading && <div className="spinner"></div>} {/* loading spinner */}
      {error && <p>{error}</p>}
      {aadhaar.length > 0 && ( // Only show the table if customers are available
        <>
          <AadhaarTable
            TABLE_ROWS={aadhaar}
            HEAD={['full_name', 'full_address','aadhaar_number','dob','gender']}
            datakey={['full_name', 'full_address','aadhaar_number','dob','gender']}          />
          <Pagination
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            activePage={currentPage}
            setActivePage={handleSetActivePage}
          />
        </>
      )}
    </div>
  );
}

export default AadhaarList;

