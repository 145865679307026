import React, { useState } from 'react';
import AadhaarModal from './aadhaarModal';

const AadhaarTable = ({ HEAD, TABLE_ROWS, datakey }) => {
  const [selectedAadhaar, setSelectedAadhaar] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRowClick = (aadhaar) => {
    setSelectedAadhaar(aadhaar);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            {HEAD.map((head) => (
              <th
                key={head}
                className="border px-4 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50"
              >
                {head}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {TABLE_ROWS.map((row, index) => (
            <tr
              key={index}
              className="transition-all cursor-pointer hover:bg-gray-100"
              onClick={() => handleRowClick(row)}
            >
              {datakey.map((key) => (
                <td key={key} className="px-4  whitespace-no-wrap">
                  <div className="text-sm leading-5 text-gray-900">{row[key]}</div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      
      {/* Modal */}
      <AadhaarModal isOpen={isModalOpen} onClose={closeModal} aadhaar={selectedAadhaar} />
    </div>
  );
};

export default AadhaarTable;
