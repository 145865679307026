import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DashBoard from "../../components/DashBoard";
import Input from "../../components/Input";
import { ClosingLoan } from "../../titles/titles";
import LoanCloseComponent from "./loanCloseHeader";
// import LoanCloseCheckDetails from './loanCloseCheckDetails';
import LoanCloseDivision from "./loanCloseRight";
import LoanCloseAmount from "./loanCloseLeft";
import { LoanCloseContext } from "./LoanCloseContext";
import { AppContext } from "../../context/appContext";
import LoandetailModal from "./LoandetailModal";
import axios from "axios";
import AlertMessage from "../../components/alert/Alert";

function LoanClose(props) {
  // testing data

  let {
    sajdata,
    total1,
    total,
    finaldc11,
    finalnotice11,
    finalgst11,
    finalotc11,
    finalinterest11,
    finalamt11,
    successMessage1,
    open1,
    setOpen1,
  } = useContext(LoanCloseContext);

  const [voucherNo, setVoucherNo] = useState("");
  const { sidebarToggle, baseurl, comcode, ucode, brcode, gcode } =
    useContext(AppContext);
  const navigate = useNavigate();

  const goToDetails = (voucher_no) => {
    const data = { voucher_no: voucher_no };
    navigate(`/loan-close-voucher/`, { state: data });
  };

  const {
    register,
    setValue,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm();
  const [isOpen, setIsOpen] = useState(false);

  const onSubmit = async (data) => {
    if (total1 > 0) {
      if (total1 === total) {
        const formData1 = new FormData();
        formData1.append("fk_AccountingHead", 3);
        formData1.append("ucode", ucode);
        formData1.append("gcode", gcode);
        formData1.append("comcode", comcode);
        formData1.append("brcode", brcode);
        formData1.append("code", "code");
        formData1.append("voucher_no", 1);
        formData1.append("trdate", "2024-10-08");
        formData1.append("name", sajdata.total_values.name);
        formData1.append("closed_rate", sajdata.total_values.intrest);
        formData1.append("loanid", sajdata.total_values.loanid);
        formData1.append("close", "yes");
        formData1.append("intrest", sajdata.total_values.intrest);
        formData1.append("principle", finalamt11);
        formData1.append("interest", finalinterest11);
        formData1.append("otc", finalotc11);
        formData1.append("gst", finalgst11);
        formData1.append("notice", finalnotice11);
        formData1.append("dc", finaldc11);

        try {              

              const response = await axios.post(
                `${baseurl}accounting/add-daybook/`,
                formData1
              );

              // Check if the status code is 201 (Created)
              // console.log("iiiiiiiiiiiiiiiiiii",response.key_data);

              
              
              if (response.data.key_data === 'created') 
                {
                  alert("Created Successfully");

                  setVoucherNo(response.data.voucher_no);
                  
                } 
              else 
              {
                alert("loan Already closed");
              }
              
        } 
        catch (error) 
        {
              // Handle errors
              console.error("An error occurred:", error);

              if (error.response) {
                // Server responded with a status code outside the range of 2xx
                alert("error1");
                console.error("Error Response Data:", error.response.data);
              } else if (error.request) {
                // Request was made, but no response received
                alert("error2");
                console.error("Error Request:", error.request);
              } else {
                // Something else happened in setting up the request
                alert("error3");
                console.error("Error Message:", error.message);
              }
        }
      } 
      else 
      {
        alert("Insufficient Amount......!");
       
      }
    } 
    else 
    {
      alert("Please Enter An Amount More than Zero......!");
    }
  };

  const handleRowClick = () => {
    console.log("fhgh", sajdata);
    if (sajdata === null) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const partclose = async (data) => {
    
    // console.log("iiiiiiiiiiiiiiiiiii",total1 + 10, total);

    // const onSubmit = async (data) => {



    if (total1 > 0) {

      if (total1 + 9 < total) { 


        const formData1 = new FormData();
        formData1.append("fk_AccountingHead", 3);
        formData1.append("ucode", ucode);
        formData1.append("gcode", gcode);
        formData1.append("comcode", comcode);
        formData1.append("brcode", brcode);
        formData1.append("code", "code");
        formData1.append("voucher_no", 1);
        formData1.append("trdate", "2024-10-08");
        formData1.append("name", sajdata.total_values.name);
        formData1.append("closed_rate", sajdata.total_values.intrest);
        formData1.append("loanid", sajdata.total_values.loanid);
        formData1.append("close", "no");
        formData1.append("intrest", sajdata.total_values.intrest);
        formData1.append("principle", finalamt11);
        formData1.append("interest", finalinterest11);
        formData1.append("otc", finalotc11);
        formData1.append("gst", finalgst11);
        formData1.append("notice", finalnotice11);
        formData1.append("dc", finaldc11);

        try {
          
            
              const response = await axios.post(
                `${baseurl}accounting/add-daybook/`,
                formData1
              );

              // Check if the status code is 201 (Created)
              // console.log("iiiiiiiiiiiiiiiiiii",response.key_data);

              
              
              if (response.data.key_data === 'created') 
                {
                  alert("Created Successfully");

                  setVoucherNo(response.data.voucher_no);
                  
                } 
              else 
              {
                alert("loan Already closed");
              }
              
        } 
        catch (error) 
        {
              // Handle errors
              console.error("An error occurred:", error);

              if (error.response) {
                // Server responded with a status code outside the range of 2xx
                alert("error1");
                console.error("Error Response Data:", error.response.data);
              } else if (error.request) {
                // Request was made, but no response received
                alert("error2");
                console.error("Error Request:", error.request);
              } else {
                // Something else happened in setting up the request
                alert("error3");
                console.error("Error Message:", error.message);
              }
        }
      } 
      else 
      {
        
        alert("Reduce 10Rs And Pay......!"); 
       
             
      }
    } 
    else 
    {
      alert("Please Enter An Amount More than Zero......!");
    }





    
    
   
   
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <DashBoard />

      {successMessage1 && open1 && console.log("checq", successMessage1, open1)}
      <div
        className={`${
          !sidebarToggle ? "ms-64 duration-500" : "ms-0 duration-500"
        }`}
      >
        <section className="pt-20 mb-5">
          <h3 className="text-center text-2xl font-bold ">Loan RePay</h3>
          {successMessage1 && open1 && (
            <AlertMessage
            color='red'
              open={open1}
              setOpen={setOpen1}
              message={successMessage1}
            />
          )}
          <LoanCloseComponent />
        </section>

        <section className="ps-5 flex flex-col md:flex-row justify-around">
          <LoanCloseAmount />
          <LoanCloseDivision />
        </section>
        <section className="flex w-full md:flex-row lg:flex-row flex-col m-auto p-5 justify-center">
          <div className="flex lg:flex-row flex-col justify-right">
            <button
              type="button"
              onClick={handleSubmit(onSubmit)}
              className="bg-gray-700 w-full hover:bg-gray-950 text-white py-2 px-4 rounded mr-5 mt-2"
            >
              Close Loan
            </button>
          </div>
          <div className="flex lg:flex-row flex-col justify-center">
            <button
              type="button"
              className="bg-gray-700 w-full hover:bg-gray-950 text-white py-2 px-4 rounded  mr-5 mt-2"
              onClick={partclose}
            >
              Partial Payment
            </button>
          </div>
          <div className="flex lg:flex-row flex-col justify-center">
            <button
              type="button"
              className="bg-gray-700 w-full hover:bg-gray-950 text-white py-2 px-4 rounded mt-2"
              onClick={handleRowClick}
            >
              Detail
            </button>
          </div>
          <div className="flex lg:flex-row flex-col justify-center">
            <button
              type="button"
              className="bg-gray-700 w-full hover:bg-gray-950 text-white py-2 px-4 rounded mt-2"
              onClick={() => goToDetails(voucherNo)}
            >
              Print
            </button>
          </div>
          <LoandetailModal isOpen={isOpen} onClose={closeModal} />
        </section>
      </div>
    </div>
  );
}

export default LoanClose;
