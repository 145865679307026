import React, {useState} from 'react';
import AadharModal from '../../components/modal/aadharmodal'
import axios from 'axios'
function Demo(props) {
    const [open, setOpen] = useState(false)
    let data = {
        "@entity": "in.co.sandbox.kyc.aadhaar.okyc.otp.request",
        "consent": "y",
        "reason": "For KYC",
        "aadhaar_number": "431307725647"
      }
    
    
    return (
        <div>
            <AadharModal isOpen={open}/>
            <button onClick={()=>setOpen(true)}>verfiy</button>
            
        </div>
    );
}
export default Demo;