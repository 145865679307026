import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import DashBoard from '../../components/DashBoard';
import { Table } from '../../components/table/Table';
import Pagination from '../../components/Pagination';
import { AppContext } from '../../context/appContext';

function VoucherHeadList() {
  const [voucherhead, setVoucherhead] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;
  let { baseurl, comcode, sidebarToggle } = useContext(AppContext)
  const column = ['COMPANY CODE','BRANCH CODE','VOUCHER HEAD CODE']
  const datakey = ['comcode','brcode','head_code']

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseurl}accounting/get-voucher-head-list/${comcode}/`, {
        params: {
          page: currentPage
        }
      });
      setVoucherhead(response.data.results.data);
      console.log('sfrg',response);
      
      setTotalItems(response.data.count);
      setError('');
    } catch (error) {
      setError(error.response.data.error);
      setVoucherhead([]);
    }
    setLoading(false);
  };
  const handleSetActivePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    handleSearch();
  }, [currentPage]);


  return (
    <div>
      <DashBoard />
      <div className={`${!sidebarToggle?'ms-64 duration-500': 'ms-0 duration-500'}`}>

      <h1 className='pt-20 text-2xl text-center'>VoucherHead List</h1>

      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      <Table TABLE_ROWS={voucherhead} datakey={datakey} HEAD={column} />      
      <Pagination
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        activePage={currentPage}
        setActivePage={handleSetActivePage}
      />
    </div>
    </div>
  );
}

export default VoucherHeadList;


