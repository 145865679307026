import React, { useState, useContext } from "react";
import { AppContext } from "../../context/appContext";
import { useForm } from "react-hook-form";
import Input from "../../components/Input";
import SimpleTable from "../../components/table/SimpleTable";
import AlertMessage from "../../components/alert/Alert";

const SchemeForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();
  const [interestData, setInterestData] = useState({
    days: "",
    intrest: "",
    other_charge: "",
  });
  const [interestList, setInterestList] = useState([]);
  const { baseurl,sidebarToggle, comcode, brcode, ucode, gcode } = useContext(AppContext);
  const [monthlyVerifying, setMonthlyVerifying] = useState("");
  const [penalType, setPenalType] = useState("");
  const [open, setOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const formData = watch();
  const onSubmit = async (formData) => {
    try {
      if (interestList.length === 0) {
        alert("Please add at least one interest entry.");
        return;
      }

      formData.comcode = comcode;
      formData.brcode = brcode;
      formData.gcode = gcode;
      formData.ucode = ucode;

      formData.monthly_vering = monthlyVerifying === "true";

      // Prepare data to send in one API call
      const requestData = {
        ...formData,
        loanintrest_set: interestList.map((interest) => ({
          ...interest,
          comcode,
          brcode,
          ucode,
          gcode,
        })),
      };

      // Make API call to create loan scheme and add interest entries
      const response = await fetch(`${baseurl}loanschemes/add-scheme/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const result = await response.json();

      console.log("response:", result);

      if (response.status === 201) {
        // Reset form and state after successful submission
        reset();
        setInterestList([]);
        setInterestData({ days: "", intrest: "", other_charge: "" });
        setPenalType("");
        setMonthlyVerifying("");
        // alert("Loan scheme added successfully!");
        setSuccessMessage(result.message);
        console.log(result.message);
        
        setOpen(true);
      } else if (response.status === 400) {
        // Handle validation errors from server
        for (const [key, value] of Object.entries(result)) {
          console.log("err", result.error);
          setError(key, {
            type: "server",
            message: value,
          });
        }
      }
    } catch (error) {

      console.error("Error submitting data:", error);
    }
  };

  const handleAddInterest = () => {
    clearErrors(["days", "intrest", "other_charge"]);
    if (
      !interestData.days ||
      !interestData.intrest ||
      !interestData.other_charge
    ) {
      if (!interestData.days) {
        setError("days", {
          type: "manual",
          message: "This field is required",
        });
      }

      if (!interestData.intrest) {
        setError("intrest", {
          type: "manual",
          message: "This field is required",
        });
      }
      if (!interestData.other_charge) {
        setError("other_charge", {
          type: "manual",
          message: "This field is required",
        });
      }
      setTimeout(() => {
        clearErrors(["days", "intrest", "other_charge"]);
      }, 2000);

      return;
    }
    // Convert days input to number
    if (
      interestData.days ||
      interestData.intrest ||
      interestData.other_charge
    ) {
      clearErrors("days");
      clearErrors("intrest");
      clearErrors("other_charge");
      const days = Number(interestData.days);
      const schemeDuration = Number(formData.scheme_duration);
      if (days >= schemeDuration) {
        setError("days", {
          type: "manual",
          message: "Days must be less than Scheme Duration.",
        });
        setTimeout(() => {
          clearErrors("days");
        }, 2000);
        return;
      }

      // Check if there are already entries in the interest list
      if (interestList.length > 0) {
        // Get the last added interest entry
        const lastAddedInterest = interestList[interestList.length - 1];

        // Compare days with the last added interest entry's days
        if (days <= lastAddedInterest.days) {
          setError("days", {
            type: "manual",
            message:
              "Days must be greater than the last added interest entry days.",
          });
          setTimeout(() => {
            clearErrors("days");
          }, 2000);
          return;
        }
      }
    }
    setInterestList([...interestList, interestData]);
    setInterestData({ days: "", intrest: "", other_charge: "" }); // Reset interestData after adding
  };
  const handleRemoveInterest = (index) => {
    const updatedInterestList = [...interestList];
    updatedInterestList.splice(index, 1);
    setInterestList(updatedInterestList);
  };

  const handleChangeInterest = (e) => {
    const { name, value } = e.target;
    setInterestData({ ...interestData, [name]: value });
  };

  const handleChange = (e) => {
    setMonthlyVerifying(e.target.value); // Update the state with the selected value
  };

  const penalTypeHandleChange = (e) => {
    setPenalType(e.target.value); // Update the state with the selected value
  };

  return (
    <>
    <div className={`${!sidebarToggle?'ms-64 duration-500': 'ms-0 duration-500'}`}>
      <div className="static flex justify-center bg-white md:p-8 rounded-lg w-full">
        <div className="flex justify-center bg-white p-8 rounded-lg w-full">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="md:border md w-full py-10"
          >
            {successMessage && open && (
            <AlertMessage
              open={open}
              setOpen={setOpen}
              message={successMessage}
            />
          )}
            <h1 className="text-3xl font-semibold mb-6 ml-3 text-center">
              Loan Scheme
            </h1>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-1/4 md:w-1/4 px-2 mb-4">
                <label>Scheme Name</label>
                <Input
                  style={{ textAlign: "left" }}
                  defaultValue=""
                  type="text"
                  name="scheme_name"
                  className={`form-control mt-1 block w-full border ${
                    errors.scheme_name ? "border-red-500" : ""
                  }`}
                  errors={errors}
                  register={register}
                  validationSchema={{
                    required: "This field is required",
                  }}
                  required
                />
              </div>
              <div className="w-full lg:w-1/4 md:w-1/4 px-2 mb-4">
                <label>Minimum Period (in Days)</label>
                <Input
                  type="number"
                  name="minimum_period"
                  className="form-control mt-1 block w-full border"
                  defaultValue=""
                  errors={errors}
                  register={register}
                  validationSchema={{
                    required: "This field is required",
                  }}
                  required
                />
              </div>
              <div className="w-full lg:w-1/4 md:w-1/4 px-2 mb-4">
                <label>Scheme Duration (in Days)</label>
                <Input
                  type="number"
                  name="scheme_duration"
                  className="form-control mt-1 block w-full border"
                  errors={errors}
                  register={register}
                  validationSchema={{
                    required: "This field is required",
                  }}
                  required
                />
              </div>
              <div className="w-full lg:w-1/4 md:w-1/4 px-2 mb-4">
                <label>Penal Charge</label>
                <Input
                  type="number"
                  name="penal_intrest"
                  className="form-control mt-1 block w-full border"
                  defaultValue=""
                  errors={errors}
                  register={register}
                  validationSchema={{
                    required: "This field is required",
                  }}
                  required
                />
              </div>
              <div className="w-full lg:w-1/4 md:w-1/4 px-2 mb-4">
                <label>Interest Type</label>
                <Input
                  type="number"
                  name="intrest_type"
                  className="form-control mt-1 block w-full border"
                  defaultValue=""
                  errors={errors}
                  register={register}
                  validationSchema={{
                    required: "This field is required",
                  }}
                  required
                />
              </div>
              <div className="w-full lg:w-1/4 md:w-1/4 px-2 mb-4">
                <label>Penal Type</label>
                <select
                  {...register("penal_type", { required: true })}
                  className={`w-full mt-1 h-7 border text-gray-700 rounded border-solid border-gray-300 focus:border-pink-600 focus:outline-none ${
                    errors.monthly_verifying ? "border-red-500" : ""
                  }`}
                  value={penalType} // Bind value to state
                  onChange={penalTypeHandleChange}
                >
                  <option value="">Choose</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
                {errors.penal_type && (
                  <p className="mt-1 text-red-500 text-xs italic">
                    This field is required
                  </p>
                )}
              </div>
              <div className="w-full lg:w-1/4 md:w-1/4 px-2 mb-4">
                <label>Monthly Vering</label>
                <select
                  {...register("monthly_vering", { required: true })}
                  className={`w-full mt-1 h-7 border text-gray-700 rounded border-solid border-gray-300 focus:border-pink-600 focus:outline-none ${
                    errors.monthly_verifying ? "border-red-500" : ""
                  }`}
                  value={monthlyVerifying} // Bind value to state
                  onChange={handleChange}
                >
                  <option value="">Select Vering Status</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
                {errors.monthly_vering && (
                  <p className="mt-1 text-red-500 text-xs italic">
                    This field is required
                  </p>
                )}
              </div>
            </div>
            <form></form>
            <div className="md:flex justify-end pr-10 sm: ml-10">
              <div className="flex justify-center mt-5 md:pr-10">
                <button
                  type="submit"
                  className="w-20 bg-gray-600 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <h2 className="text-2xl font-semibold mb-4 text-center">
        Interest Entries
      </h2>
      <div className="flex justify-center items-center">
        <div className="w-11/12">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-1/4 md:w-1/4 px-2 mb-4">
              <label>Days</label>
              <input
                type="number"
                name="days"
                className="form-control mt-1 block w-full border"
                value={interestData.days}
                onChange={handleChangeInterest}
              />
              {errors.days && (
                <p className="text-red-500 mt-1 text-xs italic">
                  {errors.days.message}
                </p>
              )}
            </div>
            <div className="w-full lg:w-1/4 md:w-1/4 px-2 mb-4">
              <label>Interest</label>
              <input
                type="number"
                name="intrest"
                className="form-control mt-1 block w-full border"
                value={interestData.intrest}
                onChange={handleChangeInterest}
              />
              {errors.intrest && (
                <p className="text-red-500 mt-1 text-xs italic">
                  {errors.intrest.message}
                </p>
              )}
            </div>
            <div className="w-full lg:w-1/4 md:w-1/4 px-2 mb-4">
              <label>Other Charge</label>
              <input
                type="number"
                name="other_charge"
                className="form-control mt-1 block w-full border"
                value={interestData.other_charge}
                onChange={handleChangeInterest}
              />
              {errors.other_charge && (
                <p className="text-red-500 mt-1 text-xs italic">
                  {errors.other_charge.message}
                </p>
              )}
            </div>
            <div className="w-full md:w-1/4 px-2 mb-4 mt-6">
              <button type="button" onClick={handleAddInterest}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-9 text-green-300 "
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <SimpleTable
        columns={["days", "Interest", "other Charge", "delete"]}
        data={interestList}
        datakey={["days", "intrest", "other_charge"]}
        height="h-44"
        deleteElement={true}
        setArray={setInterestList}
        deleteEle={handleRemoveInterest}
      />
      </div>
    </>
  );
};

export default SchemeForm;
