import React, { useContext, useEffect, useState } from 'react';
import DashBoard from '../../components/DashBoard';
import { Table } from '../../components/table/Table';
import { AppContext } from '../../context/appContext';

function LoanList(props) {
     let {baseurl} = useContext(AppContext)
    const column = ['AMOUNT','LOANID','PERIOD', 'DUE DATE','CLOSED DATE','CLOSED RATE', "GOLD RATE", 'SCHEME', 'INTEREST RATE']
    const key = ['amount', 'loanid','period', 'duedate', 'Closed_date', 'closed_rate', 'gold_rate', 'scheme', 'interest_rate']
    let [data,setData] = useState([])
    useEffect(()=>{
        fetch(`${baseurl}goldloan/pending-loan/?date=2024-06-17`) // api for the get request
        .then(response => response.json())
        .then((data) => {
            setData(data.data)
            console.log(data.data)
        });
    },[])
    return (
        <div>
             <DashBoard />
            {/* <ListTable columns={column} datakey={key} data={data}/> */}
            <Table HEAD={column} datakey={key} TABLE_ROWS={data} />
        </div>
    );
}

export default LoanList;