import React from "react";
import Test from "./testform";

function Mywork(props){
    return(
        <div>
            <test/>
        </div>
    )
}
export default Mywork