import React from 'react';
import DashBoard from '../../components/DashBoard';
import GroupCodeForm from './groupheadform';

function GroupCode(props) {
    return (
        <div>
            <DashBoard />
            <GroupCodeForm/>
        </div>
    );
}
export default GroupCode;