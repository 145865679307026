import React from 'react';
import DashBoard from '../../components/DashBoard';
import LoanMain from './loan';

function SearchLoan(props) {
    return (
        <div>
            <DashBoard />
            <LoanMain />
        </div>
    );
}
export default SearchLoan;