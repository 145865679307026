import React, { useContext, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import Input from '../../components/Input';
import { Title } from '../../titles/titles';
import { AppContext } from '../../context/appContext';
import AlertMessage from '../../components/alert/Alert';

function AccountingHeadForm(props) {
    const [open, setOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const { baseurl,sidebarToggle, comcode, brcode, ucode, gcode } = useContext(AppContext);
    const [accountingHead, setAccountingHead] = useState([]);
    
    const url = `${baseurl}accounting/add-accounthead/`;
    const {
        register,
        setError,
        reset,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        const fetchGroupHead = async () => {
            try {
                const response = await fetch(`${baseurl}accounting/grouphead-list/${comcode}/`);
                if (response.ok) {
                    const data = await response.json();
                    setAccountingHead(data.data);
                    console.log(data.data);
                } else {
                    throw new Error('Failed to fetch Accounting Head');
                }
            } catch (error) {
                console.error("Error fetching Accounting Head:", error);
            }
        };
        fetchGroupHead();
    }, [baseurl]);

    const handleCancel = () => {
        reset();
    };
    const onSubmit = async (data) => {
        try {
            data.comcode = comcode;
            data.brcode = brcode;
            data.ucode = ucode;
            data.gcode = gcode;

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const result = await response.json();

            if (response.status === 400) {
                console.log('error', result.error)
                for (const [key, value] of Object.entries(result.error)) {
                    setError(key, {
                        type: 'server',
                        message: value
                    })
                }
            }
            else if (response.status === 201) {
                handleCancel();
                setSuccessMessage(result.message);
                setOpen(true);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <div>
            <div className={`${!sidebarToggle?'ms-64 duration-500': 'ms-0 duration-500'}`}>
                <div className="flex justify-center bg-white md:p-8 rounded-lg w-full">
                <form className="md:border md w-full py-10" onSubmit={handleSubmit(onSubmit)}>
                    <Title title="Add Accounting Head" />
                    {successMessage && open && <AlertMessage open={open} setOpen={setOpen} message={successMessage} />}
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2 px-2 mb-4">
                            <label>Choose Head</label>
                            <select
                                id="voucher-head-select"
                                {...register("choose", { required: "This field is required" })}
                                className="overflow-scroll form-control text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none w-full h-6.8 mt-1"
                                onChange={(e) => {
                                    const [selectedAccountingHeadId] = e.target.value.split('|');
                                    setValue("fk_grouphead", selectedAccountingHeadId, { shouldValidate: true, shouldDirty: true });
                                }}
                            >
                                <option value="">Select Head</option>
                                {accountingHead && accountingHead.map((head_code, index) => (
                                    <option key={`${head_code.group_hcode}-${index}`} value={`${head_code.id}`}>
                                        {head_code.group_hcode} - {head_code.name}
                                    </option>
                                ))}
                            </select>
                            {errors.choose && <p className='text-red-500 text-xs italic'>{errors.choose.message}</p>}
                        </div>
                        <div className='w-full md:w-1/2 px-2 mb-4'>
                        <Input
                                style={{ textAlign: 'left' }}
                                type="text"
                                name="name"
                                label="Name"
                                errors={errors}
                                register={register}
                                validationSchema={{
                                    required: "This field is required",
                                    minLength: {
                                        value: 3,
                                        message: "Please enter a minimum of 3 characters"
                                    }
                                }}
                                required
                            />
                        </div>
                        <div className=' w-full md:w-1/2 px-2 mb-4'>
                        <Input
                                style={{ textAlign: 'left' }}
                                type="text"
                                name="head_code"
                                label="Head Code"
                                errors={errors}
                                register={register}
                                validationSchema={{
                                    required: "This field is required",
                                    minLength: {
                                        value: 3,
                                        message: "Please enter a minimum of 3 characters"
                                    }
                                }}
                                required
                            />
                        </div>
                        <div className='hidden w-full md:w-1/2 px-2 mb-4'>
                            <label>fk_grouphead</label>
                            <input
                                type="text"
                                readOnly
                                className="form-control text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none w-full h-6.5 mt-1 rounded"
                                {...register("fk_grouphead", { required: true })}
                            />
                            {errors.fk_grouphead && <p className="text-red-500 text-xs italic">This field is required</p>}
                        </div>
                     
                        <div className="w-full md:w-1/2 px-2 mb-4">
                            <Input
                                style={{ textAlign: 'left' }}
                                type="text"
                                name="description"
                                label="Description (optional)"
                                errors={errors}
                                register={register}
                            />
                        </div>
                    </div>
                    <div className='md:flex justify-end pr-10 sm: ml-10'>
                        <div className="flex justify-center mt-5 md:pr-10">
                            <button
                                type="submit"
                                className="w-20 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Submit
                            </button>
                        </div>
                        <div className="flex justify-center mt-5">
                            <button
                                type="button"
                                onClick={handleCancel}
                                className="w-20 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            </div>
        </div>
    );
}

export default AccountingHeadForm;
