import React, { useContext, useState } from "react";
import { ClassNames } from "../../styles/style";
import { useForm } from "react-hook-form";
import { LoanContext } from "../../context/loanContext";
import { AppContext } from "../../context/appContext";
import SimpleTable from "../../components/table/SimpleTable";
import { PlusIcon } from "@heroicons/react/24/outline";
import AlertMessage from "../../components/alert/Alert";
import './goldloan.css'

function GoldDetails() {
  let { formData, setFormData, items, goldrate } = useContext(LoanContext); // loan context => global states for goldloan

  // column title for pledge table
  let column = [
    "Name",
    "Description",
    "Weight",
    "Count",
    "Net Weight",
    "Purity",
    "Action",
  ];

  // data object key for pledge table
  let datakey = [
    "item",
    "description",
    "weight",
    "count",
    "ntweight",
    "purity",
  ];

  const { baseurl } = useContext(AppContext);
  let [goldDetail, setGoldDetail] = useState({});

  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const {
    // register,
    // setValue,
    // handleSubmit,
    formState: { errors },
  } = useForm();

  const addGoldDetails = () => {
    if (
      goldDetail.item &&
      goldDetail.count &&
      goldDetail.weight &&
      goldDetail.purity &&
      goldDetail.ntweight
    ) {
      if (
        parseFloat(goldDetail.count) < 0 ||
        parseFloat(goldDetail.weight) < 0 ||
        parseFloat(goldDetail.purity) < 0 ||
        parseFloat(goldDetail.ntweight) < 0
      ) {
        setError(
          "Count, Purity, Weight and Net Weight Must Be Greater Than Zero."
        ); setOpen(true);
        return;
      } else {
        if (parseFloat(goldDetail.weight) < parseFloat(goldDetail.ntweight)) {
          setError("Net weight must be less than weight.");
          setOpen(true);
          // setGoldDetail({'item': '', 'count': '', 'description': '', 'weight': '','purity': '', 'ntweight': ''})
          return;
        }
        goldDetail.sl_no = formData.details.length + 1;
        let temp = 0;
        if (formData.details.length > 0) {
          temp = formData.details.reduce((accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue.ntweight);
          }, 0);
        }

        setFormData((prev) => ({
          ...prev,
          details: [...prev.details, goldDetail],
          totalWeight: parseFloat(
            (prev.totalWeight + parseFloat(goldDetail.ntweight)).toFixed(3)
          ),
        }));
        setFormData((prev) => ({
          ...prev,
          max_amount: Math.round(parseFloat(prev.totalWeight) * goldrate),
        }));

        setGoldDetail({
          item: "",
          count: "",
          description: "",
          weight: "",
          purity: "",
          ntweight: "",
        });
      }
    } else {
      setError("Please fill pledge details.");
      setOpen(true);
    }
  };

  // for select name of item
  function handleChangeItem(e) {
    setGoldDetail({ ...goldDetail, [e.target.name]: e.target.value });
  }

  // for add pledge details
  const handleChange = (e) => {
    setGoldDetail({ ...goldDetail, [e.target.name]: e.target.value });
  };

  // delete elements from pledge table
  const deleteEle = (i) => {
    const ele =
      formData.details && formData.details.filter((item, index) => i !== index);

    // calculate total weight after deletion
    let temp = ele.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.ntweight);
    }, 0);

    // update formdata after element deletion
    setFormData((prev) => ({
      ...prev,
      details: ele,
      totalWeight: temp,
      max_amount: Math.round(temp * goldrate),
    }));
  };

  const classes =
    "form-control mt-1 flex justify-between ps-2 w-full md:w-1/2 flex-col";

  return (
    <div className="px-2 border pb-4 mt-10">
      {/* alert message */}
      {open && error && (
        <AlertMessage
          color="red"
          message={error}
          open={open}
          setOpen={setOpen}
        />
      )}

      <section className="flex">
        <div className={classes}>
          <label className={ClassNames.labelClass}>Name</label>
          <select
            value={goldDetail.item}
            onChange={handleChangeItem}
            // {...register("mode", { required: true })}
            id="sal"
            name="item"
            label="Mode"
            className="w-full h-7 border mt-1 text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            defaultValue="Choose"
            // register={register}
          >
            <option value="">Choose Item </option>
            {items &&
              items.map((data, index) => {
                return (
                  <option key={index} value={data.item}>
                    {data.item}
                  </option>
                );
              })}
            {/* <option value=""></option>
                                <option value=""></option>
                                <option value=""></option> */}
          </select>
        </div>
        <div className={classes}>
          <label className={ClassNames.labelClass}>Description</label>
          <input
            type="text"
            name="description"
            value={goldDetail.description}
            onChange={handleChange}
            className="form-control  text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            required
          />
        </div>
        <div className={classes}>
          <label className={ClassNames.labelClass}>Count</label>
          <input
            type="number"
            name="count"
            onChange={handleChange}
            value={goldDetail.count}
            className="form-control no-spinner text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            required
          />
        </div>
        <div className={classes}>
          <label className={ClassNames.labelClass}>Purity</label>
          <input
            type="number"
            name="purity"
            onChange={handleChange}
            value={goldDetail.purity}
            className="form-control no-spinner text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            required
          />
        </div>
        <div className={classes}>
          <label className={ClassNames.labelClass}>Weight</label>
          <input
            type="number"
            name="weight"
            onChange={handleChange}
            value={goldDetail.weight}
            className="form-control no-spinner text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            required
          />
        </div>

        <div className={classes}>
          <label className={ClassNames.labelClass}>Net. Weight</label>
          <input
            type="number"
            name="ntweight"
            onChange={handleChange}
            value={goldDetail.ntweight}
            className="form-control no-spinner text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            required
          />
        </div>
        <div className={classes}>
          <PlusIcon
            onClick={addGoldDetails}
            className="h-7 w-7 border mt-5 border-lime-500 text-green-500"
          />
        </div>

        {/* <button className='btn' onClick={addGoldDetails}>Add</button> */}
      </section>
      {/* <button onClick={() => onSubmit(formData)}>Submit</button><br/> */}

      {/* pledge table */}
      <SimpleTable
        columns={column}
        data={formData.details && formData.details}
        datakey={datakey}
        deleteEle={deleteEle}
        deleteElement={true}
        height="h-56"
      />
      {/* end of pledge table */}

      <section className="flex">
        <div className={classes}>
          <label className={ClassNames.labelClass}>Total Weight</label>
          <input
            type="number"
            name=""
            // onChange={handleChange}
            value={formData.totalWeight && formData.totalWeight}
            className="form-control text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            readOnly
          />
        </div>
        <div className={classes}>
          <label className={ClassNames.labelClass}>Loan Amount</label>
          <input
            type="number"
            name=""
            // onChange={handleChange}
            value={formData.goldloan && parseFloat(formData.goldloan.amount)}
            className="form-control text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            required
          />
        </div>
        <div className={classes}>
          <label className={ClassNames.labelClass}>Amount</label>
          <input
            type="number"
            name=""
            // onChange={handleChange}
            value={formData.max_amount && formData.max_amount}
            className="form-control text-right w-full px-3  text-gray-700 rounded border border-solid border-gray-300 focus:border-pink-600 focus:outline-none"
            readOnly
          />
        </div>
      </section>
    </div>
  );
}

export default GoldDetails;
