import React, { useContext, useEffect } from 'react';
import NavBar from './NavBar';
import SideBar from './SideBar';
import { AppContext } from '../context/appContext';


function DashBoard(props) {
    let{ sideNavRef, setSidebarToggle } = useContext(AppContext)
    useEffect(() => {
            // Add event listener to the document object
            document.addEventListener('mousedown', handleClickOutside);
        
            // Remove event listener when the component unmounts
            return () => {
              document.removeEventListener('mousedown', handleClickOutside);
            };
          }, []);
        
          function handleClickOutside(event) {
            if (sideNavRef.current && !sideNavRef.current.contains(event.target)) {
              // Clicked outside the side navigation bar, close it
              // Implement your close side navigation bar logic here
              setSidebarToggle(true)
            }
          }
    
    
    return (
        <div ref={sideNavRef} >
           
            <NavBar />
            <SideBar/>
            
        </div>
    );
}

export default DashBoard;