import React, { useState } from 'react';
import CustomerModal from './CustomerModal'; // Adjust the path based on your actual file structure

const Table = ({ HEAD, TABLE_ROWS, datakey }) => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRowClick = (customer) => {
    setSelectedCustomer(customer);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="overflow-x-auto mt-5">
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            {HEAD.map((head) => (
              <th
                key={head}
                className="border px-2 py-2 text-xs font-medium leading-4 tracking-wider text-left text-gray-700 uppercase bg-gray-200"
              >
                {head}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {TABLE_ROWS.map((row, index) => (
            <tr
              key={index}
              className="transition-all cursor-pointer hover:bg-gray-100"
              onClick={() => handleRowClick(row)}
            >
              {datakey.map((key) => (
                <td key={key} className="px-2 whitespace-no-wrap">
                  <div className="text-sm leading-5 text-gray-900">
                    {key==='aadhaarverified'?row[key]?'Verified':'Not Verified':row[key]}</div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      
      {/* Modal */}
      <CustomerModal isOpen={isModalOpen} onClose={closeModal} customer={selectedCustomer} />
    </div>
  );
};

export default Table;
