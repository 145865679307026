import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Title } from '../../titles/titles';
import DashBoard from '../../components/DashBoard';
import { AppContext } from '../../context/appContext';
import ApprovalModal from '../../components/ApprovalModal';
import DaybookTableComponent from '../../components/ApproveTable';
import {
    IsDeviceConnected,
    CaptureFinger,
    GetConnectedDeviceList,
    InitDevice,
    GetMorFinAuthInfo,
    GetTemplate,
    VerifyFinger,
  } from "../goldloan/morfinauth";
function DaybookTable() {
    const [daybooks, setDaybooks] = useState([]);
    const [selectedDaybookId, setSelectedDaybookId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [approverId, setApproverId] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 5; // Define the number of rows per page
    const { baseurl,sidebarToggle,ucode } = useContext(AppContext);
    const now = new Date();
    const date = now.toISOString().split('T')[0];
    const time = now.toTimeString().split(' ')[0];
    const approvedtime = `${date} ${time}`;

    const [fin, setFin] = useState('')
    const [fpMessage, setFpMessage] = useState('')
    const [userId,setUserId] = useState('')


    useEffect(() => {
        fetchData();
        setApproverId(123);
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${baseurl}accounting/filter-approval/`);
            setDaybooks(response.data.data);
        } catch (error) {
            console.error('Error fetching daybooks:', error);
        }
    };

    const handleApprove = (daybookId) => {
        setSelectedDaybookId(daybookId);
        setShowModal(true);
    };

    const confirmApprove = async (approverId) => {
        
        const approvalData = {
            approvedby: approverId,
            approvedtime: approvedtime,
        };
       
             try {
                await axios.post(`${baseurl}accounting/daybooks/approve/${selectedDaybookId}/`, approvalData);
                setDaybooks(daybooks.map(daybook => {
                    if (daybook.id === selectedDaybookId) {
                        return { ...daybook, approved: true, approvedby: approverId, approvedtime: approvalData.approvedtime };
                    }
                    return daybook;
                }));
            } catch (error) {
                console.error('Error approving daybook:', error);
            }
            setShowModal(false);
       
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
  
    return (
        <div>
            <DashBoard />
            <div className={`${!sidebarToggle?'ms-64 duration-500': 'ms-0 duration-500'}`}>
            <Title title='Pending Approval' />
            <DaybookTableComponent
                daybooks={daybooks}
                onApprove={handleApprove}
                TABLE_HEAD={["hcode", "Credit", "Debit", "Type", "Mode", "Description", "Approved", "Approve"]}
                datakey={["hcode", "credit", "debit", "typ", "mode", "description", "approved",]}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                onPageChange={handlePageChange}
            />
            <ApprovalModal
                isVisible={showModal}
                onClose={closeModal}
                onConfirm={confirmApprove}
                // userId={userId}
                // setUserId={setUserId}
            
            />
        </div>
        </div>
    );
}

export default DaybookTable;