import React, { useContext, useState } from "react";
import Input from "../../components/Input";
import { useForm } from "react-hook-form";
import "./login.css";
import { Title } from "../../titles/titles";
import { AppContext } from "../../context/appContext";
import { useNavigate } from "react-router";
import { jwtDecode } from "jwt-decode";
import { Button } from "@material-tailwind/react";


function LoginForm(props) {
  const { baseurl, setUser, setAuthToken, setBrcode, setUcode, setComcode } =
    useContext(AppContext);
  const navigate = useNavigate();
  const url = `${baseurl}user/api/token/`;
  const [isLoading, setIsLoading] = useState(false)

  const classes = "form-control mt-4 block justify-between w-full";
  const {
    register,
    setError,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true)
    try {
      const response = await fetch(url, {
        method: "POST",
        // credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();

      // reset()
      // alert(result.message)

      if (response.status === 400) {
        console.log(typeof result.error);
        
        setIsLoading(false)
        for (const [key, value] of Object.entries(result.error)) {
          setError(key, {
            type: "server",
            message: value,
          });
        }
      } else if (response.status === 200) {
        reset();
        setUser(jwtDecode(result.access));
        setBrcode(jwtDecode(result.access).brcode);
        setComcode(jwtDecode(result.access).comcode);
        setUcode(jwtDecode(result.access).user_id);
        
        setAuthToken(result.access);
        localStorage.setItem("token", result.access);
        setIsLoading(false)
        navigate("/homepage");
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false)

    }
  };
  return (
    <div className="flex justify-center ">
      <div className="border bg-white p-8 rounded-lg w-full md:w-1/2 custom-width ">
        <Title title="LOGIN" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes}>
            <label className="block text-gray-700 text-sm font-bold">
              Employee Id
            </label>
            <Input
              type="text"
              className=" h-20 "
              name="username"
              style={{ textAlign: "left" }}
              errors={errors}
              register={register}
              validationSchema={{
                required: "This field is required",
                minLength: {
                  value: 3,
                  message: "Please enter a minimum of 3 characters",
                },
              }}
              required
            />
          </div>
          <div className={classes}>
            <label className="block text-gray-700 text-sm font-bold">
              Password{" "}
            </label>
            <Input
              style={{ textAlign: "left" }}
              type="password"
              name="password"
              errors={errors}
              register={register}
              validationSchema={{
                required: "This field is required",
                minLength: {
                  value: 3,
                  message: "Please enter a minimum of 3 characters",
                },
              }}
              required
            />
          </div>
          <div className="flex justify-center mt-10">
            <Button
              type="submit"
              className="bg-gray-700 w-full hover:bg-gray-950 text-white py-2 px-4 rounded"
              loading={isLoading}
            >
            
              Login
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
