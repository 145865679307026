import React from 'react';
import DashBoard from '../../components/DashBoard';
import AccountingHeadForm from './accountingheadform';

function AccountingHead(props) {
    return (
        <div>
            <DashBoard />
            <AccountingHeadForm/>
        </div>
    );
}
export default AccountingHead;