import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import './voucherprint.css';
import { AppContext } from '../../context/appContext';
import { useLocation } from 'react-router';

function VoucherPrint() {
    const location = useLocation() 
    const data = location.state
    console.log(data)
    const [voucherNo, setVoucherNo] = useState('');
    const [totalCredit, setTotalCredit] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searched, setSearched] = useState(false);
    const [daybooks, setDaybooks] = useState(false);
    const [searchSuccessful, setSearchSuccessful] = useState(false);
    const { baseurl,comcode, brcode } = useContext(AppContext);
    

    const handleSearch = async (voucherNo) => {
        if (!voucherNo) {
            // alert("Please enter a voucher number");
            return;
        }

        setLoading(true);
        setError(null);
        setSearched(true);
        setSearchSuccessful(false); // Reset search successful state

        try {
            const response = await axios.get(`${baseurl}accounting/closing-voucher/${comcode}/${brcode}/${voucherNo}/`);
            console.log('API Response:', response.data.data); // Log the full response for debugging
            setTotalCredit(response.data.data.total_credit);
            setDaybooks(response.data.data)
            setSearchSuccessful(true); // Mark search as successful
        } catch (err) {
            console.error('API Error:', err); // Log error for debugging
            setError(err.message);
            setSearchSuccessful(false); // Mark search as unsuccessful
        } finally {
            setLoading(false);
        }
    };

    const handlePrint = () => {
        window.print();
    };
useEffect(()=>{
    data&&setVoucherNo(data.voucher_no);
    handleSearch(data.voucher_no);

},[data])
    return (
        <div className="w-8/12 mr-auto bg-white ml-auto font-medium" style={{ fontFamily: 'sans-serif' }}>
            <div className="text-center mt-5 no-print">
                <input
                    type="text"
                    placeholder="Enter voucher number"
                    value={voucherNo}
                    onChange={(e) => setVoucherNo(e.target.value)}
                    className="border p-2"
                />
                <button
                    onClick={()=>handleSearch(voucherNo)}
                    className="ml-3 bg-gray-500 hover:bg-gray-700 text-white px-4 py-2 rounded"
                >
                    Search
                </button>
                <button
                    onClick={handlePrint}
                    className={`ml-3 ${searchSuccessful ? 'bg-gray-500  hover:bg-gray-700' : 'bg-gray-500' } text-white px-4 py-2 rounded`}
                    disabled={!searchSuccessful}
                >
                    Print
                </button>
            </div>

            {searched && (
                <div className="page" id="page3">
                    <div className="w-full">
                        {loading && <div>Loading...</div>}
                        {error && <div>Error: {error}</div>}
                        {totalCredit !== null && !loading && !error && (
                            <div className="voucher-content">
                                <div className='mr-auto ml-auto' style={{ padding: '20px', backgroundColor: 'white', width: '100%', fontFamily: 'TimesRoman, Times New Roman, serif' }}>
                                    <div className='text-center'>
                                        <p className='text-2xl'>{daybooks.company_name.toUpperCase()}</p>
                                        <p>Chemmannur Coperate office, No.19, 3rd Cross,</p>
                                        <p>KCD Complex, Mission Road Bangalore:560002</p>
                                        <p>Tel: 080 22221066, 22221115</p>
                                        <p>Regd  : J Block ,Unity Building, J.C.Road, Bangalore- 560002</p>
                                        <p>Email: headoffice@chemmfinance.com</p>
                                        <p>GST : U85110KA1993PLC014599</p>
                                    </div>
                                    <div className='flex justify-between mt-3'>
                                        <span>Date</span>
                                        <span>{daybooks.records[0].trdate}</span>
                                        <span>Receipt</span>
                                        <span>No:</span>
                                        <span>{daybooks.records[0].voucher_no}</span>
                                    </div>
                                </div>
                                <hr />
                                <div className='w-3/4 mr-auto ml-auto flex justify-between mt-10'>
                                    <span>Received from</span>
                                    <span>{daybooks.records[0].name.toUpperCase()}</span>
                                    <span>an amount of</span>
                                </div>
                                <div className='w-1/2 flex justify-between mt-10' style={{ marginLeft: '150px' }}>
                                    <span>Rs.</span>
                                    <span>{totalCredit.toLocaleString()} by cash</span>
                                    <span>.</span>
                                </div>
                                <div className='w-1/2 flex justify-between mt-10' style={{ marginLeft: '150px' }}>
                                    <span>towards Closing of goldloan No: <span className='ml-5'>{daybooks.records[0].code.toUpperCase()}</span> </span>
                                </div>
                                <div className='w-full flex justify-around mt-10'>
                                    <span>Signature of Customer</span>
                                    <span>For <span>{daybooks.branch_name.toUpperCase()} <br />fax : 080-22210559 Bangalore</span></span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default VoucherPrint;
