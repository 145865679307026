import React, { useState, useEffect,useContext } from 'react';
import axios from 'axios';
import { AppContext } from '../../context/appContext';
import Pagination from '../../components/Pagination';

const PageTable = () => {
    const [pageDetail, setPageDetail] = useState([]);
    const {baseurl,sidebarToggle}= useContext(AppContext);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage =5;

   
    useEffect(() => {
        fetchPages();
    }, [currentPage]);

    const fetchPages = async () => {
        try {
            const response = await axios.get(`${baseurl}user/get-page/`, {
                params: { page: currentPage }
            });
           
            
            setPageDetail(response.data.results.data); // Assuming your API response structure
            console.log(response);
            
            setTotalItems(response.data.count);
        } catch (error) {
            console.error('Error fetching data: ', error);
        }
    };

    const handleSetActivePage = (pageNumber) => {
        setCurrentPage(pageNumber);
      };

    return (
        <>
        <div className={`${!sidebarToggle?'ms-64 duration-500': 'ms-0 duration-500'}`}>
        <div className="container mx-auto px-4 py-8 pt-20">
            <h2 className="text-2xl font-bold mb-4 text-center">Page List</h2>
            <div className="overflow-x-auto">
                <div className="overflow-y-auto">
                    <table className="min-w-full bg-white border-gray-200 shadow-sm rounded-lg overflow-hidden">
                        <thead className="bg-gray-100">
                            <tr>
                                
                                
                                <th className="px-4 py-2 text-left">Page Number</th>
                                <th className="px-4 py-2 text-left">Page Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pageDetail.map((pageDetail, index) => (
                                <tr key={index} className="border-b border-gray-200 cursor-pointer hover:bg-gray-50">
                                    
                                    
                                    <td className="px-4 py-3">{pageDetail.pagenumber}</td>
                                    <td className="px-4 py-3">{pageDetail.pagename}</td>                                    
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <Pagination
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        activePage={currentPage}
        setActivePage={handleSetActivePage}
      />
       </div>
        </>
    );
};

export default PageTable;
